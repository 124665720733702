import React from 'react';
import { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import { GoogleLogin } from 'react-google-login';

import EncodedGrades from './EncodedGrades';
import EncodedIncome from './EncodedIncome';
import DocumentViewer from './DocumentViewer';
import Rankings from './Rankings';

import GoogleLogout from './GoogleLogout';

import { 
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Image,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    CircularProgress,
    Backdrop,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { API_URL } from './config';
// import { AUTH_HEADER } from './config';
import { CURR_TERMID } from './config';
import { isFuture } from 'date-fns';

const styles = theme => ({
    formHeader: {
        fontFamily: `"Quicksand", sans-serif`,
        fontWeight: 500,
        color:  `#d58a13`, //"#707070",
        marginTop: `15px`,
        marginBottom: `20px`

    },
    appTypeRadio: {
        // width: `50%`,
        // margin: `0 auto`
    },
    sectionHeader: {
        backgroundColor: `#d58a13`,  //`#707070a6`, //`#dc9011`, //#707070a6
        color: 'white'
    },
    sectionCardHeader: {
        fontSize: 100,
    },
    docView: {
        minHeight: "300px"
    }

});

let socket = null; 
let AUTH_HEADER = null;
const clientId = '160410271574-6jb7q1t9jb6cnkv4mrha41b6b7h4l61d.apps.googleusercontent.com';

const initialState = {
    navBtnSelected: 'DocView',

    applicationStats: null,
    accessStats: null,
    showLogin: true,
    isUserLoggedIn: false,
    username: '',
    password: '',
    userType: '',
    userProgramAccess: [],
    allApplications: [],
    allSelectedApplications: [],
    selectedFile: null,
    fileSrc: null,
    form137_remarks: '',
    selectedAppNo: '',
    selectedFileID: '6' ,
    selectedDocType: '' ,
    selectedDocName: 'Photo w/ Name Tag', 
    selectedFileType: '' , 
    annualIncomeOptions: [],
    listOfUploadedFiles: [],

    successfulFileVerification: false,
    hasClickedVerify: false,

    successfulFileRemoveVerification: false,
    hasClickedRemoveVerification: false,

    successfulFileInvalidation: false,
    hasClickedInvalidate: false,

    isApplicationValidated: false,
    isApplicationInvalidated: false,
    isApplicationInvalidStatusRemoved: false,
    isAppInvalidStatusRemovedDialogOpen: false,
    isApplicationValidationComplete: false,
    isLoadingFile: false,
    applicationFilter: 1,

    verificationRemarks: '',
}

class DocView extends Component{

    // constructor(props){
    //     super(props);
    //     this.state = initialState;
    // }

    state = {
        navBtnSelected: 'DocView',

        applicationStats: null,
        accessStats: null,
        showLogin: true,
        isUserLoggedIn: false,
        username: '',
        password: '',
        userType: '',
        userProgramAccess: [],
        allApplications: [],
        allSelectedApplications: [],
        selectedFile: null,
        fileSrc: null,
        form137_remarks: '',
        selectedAppNo: '',
        selectedFileID: '6' ,
        selectedDocType: '' ,
        selectedDocName: 'Photo w/ Name Tag', 
        selectedFileType: '' , 
        annualIncomeOptions: [],
        listOfUploadedFiles: [],

        successfulFileVerification: false,
        hasClickedVerify: false,

        successfulFileRemoveVerification: false,
        hasClickedRemoveVerification: false,

        successfulFileInvalidation: false,
        hasClickedInvalidate: false,

        isApplicationValidated: false,
        isApplicationInvalidated: false,
        isApplicationInvalidStatusRemoved: false,
        isAppInvalidStatusRemovedDialogOpen: false,
        isApplicationValidationComplete: false,
        isLoadingFile: false,
        applicationFilter: 1,
        previousApplicationStatus: 'None',

        verificationRemarks: '',
        
    };

    changeHandler = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }  

    handleNavBtnSelect = (navBtnSelected) => {
        this.setState({
            navBtnSelected: navBtnSelected
        }, () => {

        })
    }

    handleApplicationFilter = (e) => {
        this.setState({applicationFilter: e.target.value}, () => {
            this.getAllApplications(this.state.applicationFilter)
            this.resetApplicationSelection();
        });
    }

    handleUserLogin = () => {
        axios.post(`${API_URL}/admission/auth`, 
            {
                username: this.state.username,
                password: this.state.password
            }
        ) 
        .then( (response) => {
            //console.log(`authResponse: ${response.data}`);
            if(response.data.success){
                this.setState({
                    showLogin: false,
                    isUserLoggedIn: true,
                }, () => {
                    AUTH_HEADER = {
                        headers: {
                            'Authorization': `${response.data.token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                    this.getAllApplications(this.state.applicationFilter);
                    localStorage.setItem('user', this.state.username);
                    localStorage.setItem('apiToken', response.data.token);
                    socket.emit('admission:getCurrentlySelectedApps'); 
                })
            }else{
                alert(response.data.message);
            }
        })
    }

    handleUserLogout = () => {
        this.setState({
            isUserLoggedIn: false,
            showLogin: true,
            username: '',
            password: '',
            selectedAppNo: '',
        }, () => {
            localStorage.clear();
        })
    }

    getAllApplications = (filter) => {
        if(filter === 1){
            axios.get(
                `${API_URL}/admission/getAllUnvalidatedApplications/${CURR_TERMID}`,
                AUTH_HEADER
            )
            .then( (response) => {
                // //console.log(`allUnvalidatedApplications: ${JSON.stringify(response.data)}`);
                this.setState({allApplications: response.data});
            });
        }else if(filter === 2){
            //console.log(`AUTH_HEADER: ${AUTH_HEADER}`);
            axios.get(`
                ${API_URL}/admission/getAllValidatedApplications/${CURR_TERMID}`,
                AUTH_HEADER
            )
            .then( (response) => {
                //console.log(`AXIOS-response.data:${response.data}`);
                this.setState({allApplications: response.data});
            });
        }else if(filter === 3){
            axios.get(
                `${API_URL}/admission/getAllInvalidApplications/${CURR_TERMID}`,
                AUTH_HEADER
            )
            .then( (response) => {
                this.setState({allApplications: response.data});
            });
        }else{    
            axios.get(
                `${API_URL}/admission/getAllApplications/${CURR_TERMID}`,
                AUTH_HEADER
            )
            .then( (response) => {
                // //console.log(`allApplications: ${JSON.stringify(response.data)}`);
                this.setState({allApplications: response.data});
            });
        }
        
        
    }

    getListOfUploadedFiles = (appNo) => {
        if(appNo !== ''){
            axios.get(`${API_URL}/admission/getListOfUploadedFiles/${appNo}`)
                .then( (response) => {
                    //console.log(`responseListOfUploadedFiles:${response.data}`);
                    //console.log(`responseListOfUploadedFilesLength:${response.data.length}`);
                    this.setState({listOfUploadedFiles: response.data});
                    
                });
        }
        
    }

    getDocumentName = (docID) => {
        let documentName = '';
        switch (docID){
            case 1: documentName = `Form 137`; break;
            case 2: documentName = `ToR`; break;
            case 3: documentName = `Father's ITR`; break;
            case 4: documentName = `Mother's ITR`; break;
            case 5: documentName = `IP Membership Cert`; break;
            case 6: documentName = `Photo w/ Name Tag`; break;
            case 7: documentName = `Photo w/ ID Card`; break;
            case 8: documentName = `Signature`; break;
            case 9: documentName = `Income Source`; break;
            case 10: documentName = `Birth Certificate`; break;
            case 11: documentName = `IELTS Result`; break;
        }
        return documentName;
    } 
    
    getUploadedFile = (appNo,fileID) => {
        this.setState({isLoadingFile: true}, () => {
            let docType = '';
            let fileType = '';
            switch (fileID){
                case '1': docType = 'file_form137'; fileType = 'doc'; break;
                case '2': docType = 'file_ToR'; fileType = 'doc'; break;
                case '3': docType = 'file_fParentITR'; fileType = 'doc'; break;
                case '4': docType = 'file_mParentITR'; fileType = 'doc'; break;
                case '5': docType = 'file_IPmembershipCert'; fileType = 'doc'; break;
                case '6': docType = 'file_passportPhoto'; fileType = 'img'; break;
                case '7': docType = 'file_idPhoto'; fileType = 'img'; break;
                case '8': docType = 'file_applicantSignature'; fileType = 'img'; break;
                case '9': docType = 'file_incomeSource'; fileType = 'doc'; break;
                case '10': docType = 'file_birthCert'; fileType = 'doc'; break;
                case '11': docType = 'file_IELTS'; fileType = 'doc'; break;
            }
            
            axios.get(`${API_URL}/admission/getUploadedFile/${appNo}/${fileID}`,
                AUTH_HEADER
            )
                .then( (response) => {
                    if(response.data){
                        this.setState({
                            // fileSrc: response.data.fileSrc,
                            selectedFile: response.data,
                            selectedDocType: docType,
                            selectedFileType: fileType,
                            isLoadingFile: false
                        }, () => {
                            // //console.log(`selectedFile: ${JSON.stringify(this.state.selectedFile)}`)
                        });
                    }
                });
        });
    }

    isApplicantTransferee = (appNo) => {
        const applicant = this.state.allApplications.filter( (key) => {
            return key.AppNo === appNo && key.ApplyTypeID === 2
        });

        //console.log(`isTrans: ${JSON.stringify(applicant)}`);

        if(applicant.length > 0) return true;
        else return false;
        
    }

    getPreviousApplicationStatus = (appNo) => {
        
        axios.get(`${API_URL}/admission/getPreviousApplicationStatus/${appNo}`)
        .then( (response) => {

            if(response.data){
                let prevAppStatus = '';
                if(response.data.isValidated){
                    if(response.data.isEnlisted){
                        prevAppStatus = 'Enlisted';
                    }else{
                        prevAppStatus = 'Validated';
                    }
                }else if(response.data.isInvalid){
                    prevAppStatus = 'Invalidated';
                }else{
                    prevAppStatus = 'None';
                }
                
                this.setState({
                    prevAppStatus: prevAppStatus
                });
            
            }
        })
        .catch( (err) => {
            alert(`Something went wrong`);
        })
    }

    handleApplicantSelected = (e) => {
        // this.resetApplicationSelection().then( () => {
            const appNo = e.currentTarget.id;
            const isTransferee = this.isApplicantTransferee(appNo);
            //console.log(`isTransReturn: ${isTransferee}`);
            this.setState({
                selectedAppNo: appNo,
                selectedAppNoIsTransferee: isTransferee,
                selectedFileID: '6',
                selectedDocName: 'Photo w/ Name Tag', 
            }, () => {
                this.getListOfUploadedFiles(this.state.selectedAppNo);
                this.getUploadedFile(this.state.selectedAppNo,this.state.selectedFileID);
                this.getPreviousApplicationStatus(this.state.selectedAppNo);
                socket.emit('admission:applicantSelected', {appNo: this.state.selectedAppNo, user: localStorage.getItem('user')});
            });

            
        // });
        
    }

    handleFileTypeSelected = (e) => {
        const fileID = e.currentTarget.id;
        const selectedDocName = this.getDocumentName(parseInt(fileID));
    
        this.setState({
            selectedFileID: e.currentTarget.id,
            selectedDocName: selectedDocName
        }, () => {
            this.getUploadedFile(this.state.selectedAppNo,fileID);
        });
        
    }

    // Start of Document Verification Handlers 

        handleOpenConfirmRemoveVerification = () => {
            this.setState({hasClickedRemoveVerification: true});
        }
        
        handleOpenConfirmVerification = () => {
            this.setState({hasClickedVerify: true});
        }

        handleCloseConfirmVerification = () => {
            this.setState({hasClickedVerify: false});
        }

        handleCloseConfirmUnverification = () => {
            this.setState({hasClickedRemoveVerification: false});
        }
        
        handleCloseSuccessfulRemoveVerification = () => {
            this.setState({successfulFileRemoveVerification: false});
        }

        handleCloseSuccessfulInvalidStatusRemoval = () => {
            this.setState({isAppInvalidStatusRemovedDialogOpen: false}, () => {
                this.resetApplicationSelection();
            })
        }

        handleVerifySelectedFile = () => {
            const formData = {
                docID: parseInt(this.state.selectedFileID),
                appNo: this.state.selectedAppNo,
                remarks: this.state.verificationRemarks,
                userID: localStorage.getItem('user')
            };
        
            axios.post(`${API_URL}/admission/verifySubmittedDoc`, formData)
                .then( (response) => {
                    //console.log(`verifySelFileResponseStatus: ${response.data.status}`);
                    //console.log(`InvalidStatus: ${response.data.invalidStatus}`);
                    if(response.data.status){
                        this.getListOfUploadedFiles(this.state.selectedAppNo);

                        this.setState({
                            successfulFileVerification: true,
                            hasClickedVerify: false,
                            verificationRemarks: ''
                        }, () => {
                            this.getAllApplications(this.state.applicationFilter);
                            this.getUploadedFile(this.state.selectedAppNo,this.state.selectedFileID);
                            
                            
                            if(!response.data.invalidStatus && this.state.applicationFilter === 3){
                                this.setState({isApplicationInvalidStatusRemoved: true});
                                socket.emit('admission:getCurrentApplicationStats')
                            }
                        });

                    }
                    
                });
        }

        resetApplicationSelection = () => {
            this.setState({
                selectedAppNo: '',
                isApplicationValidated: false,
                isApplicationInvalidStatusRemoved: false,
                isApplicationInvalidated: false,
                isApplicationValidationComplete: false,
                selectedFile: null,
                selectedFileID: '6'
            }, () => {
                this.getListOfUploadedFiles(this.state.selectedAppNo);
            });
        }

        handleUnverifySelectedFile = () => {
            const formData = {
                docID: this.state.selectedFileID,
                appNo: this.state.selectedAppNo,
                remarks: this.state.verificationRemarks,
                userID: localStorage.getItem('user')
            };
        
            axios.post(`${API_URL}/admission/unverifySubmittedDoc`, formData)
                .then( (response) => {
                    //console.log(`unverifySelFileResponseStatus: ${response.data.status}`);
                    if(response.data.status){
                        this.getListOfUploadedFiles(this.state.selectedAppNo);

                        this.setState({
                            successfulFileRemoveVerification: true,
                            hasClickedRemoveVerification: false,
                            verificationRemarks: ''
                        }, () => {
                            // this.isApplicationValidationComplete();
                            if(this.state.applicationFilter === 2){
                                this.resetApplicationSelection();
                                socket.emit('admission:getCurrentApplicationStats')
                            }else{
                                this.getUploadedFile(this.state.selectedAppNo,this.state.selectedFileID);
                            }
                            this.getAllApplications(this.state.applicationFilter);
                        });
                    }
                    
                });
        }
    // End of Document Verification Handlers
    
    // Start of Document Invalidation Handlers 
        handleOpenConfirmInvalidation = () => {
            this.setState({hasClickedInvalidate: true});
        }

        handleCloseConfirmInvalidation = () => {
            this.setState({hasClickedInvalidate: false});
        }
        
        handleCloseSuccessfulInvalidation = () => {
            this.setState({successfulFileInvalidation: false}, () => {
                this.setState({isApplicationInvalidated: true});
            });
        }

        handleInvalidateSelectedFile = () => {
            const formData = {
                docID: this.state.selectedFileID,
                appNo: this.state.selectedAppNo,
                remarks: this.state.verificationRemarks,
                userID: localStorage.getItem('user')
            };
        
            axios.post(`${API_URL}/admission/invalidateSubmittedDoc`, formData)
                .then( (response) => {
                    if(response.data.status){
                        this.getListOfUploadedFiles(this.state.selectedAppNo);
                        //console.log(`after getListOfUploadedFiles`);
                        this.setState({
                            hasClickedInvalidate: false,
                            verificationRemarks: '',
                            successfulFileInvalidation: true
                        }, () => {
                            this.getUploadedFile(this.state.selectedAppNo,this.state.selectedFileID);
                            this.getAllApplications(this.state.applicationFilter);
                            socket.emit('admission:getCurrentApplicationStats');
                        });
                    
                    }
                    
                });
        }
    // End of Document Invalidation Handlers

    // Start of function for checking if all doc requirements are already verified

        handleCloseSuccessfulVerification = () => {
            this.setState({successfulFileVerification: false},  () => {
                this.isApplicationValidationComplete().then( (result) => {
                    if(result){
                        this.validateApplication();
                    }else{
                        if(this.state.isApplicationInvalidStatusRemoved){
                            this.setState({isAppInvalidStatusRemovedDialogOpen: true}, () => {
                                this.resetApplicationSelection();
                            })
                        }
                    }
                });
                
                
            });
        }

        isApplicationValidationComplete =  () => {
            const appNo = this.state.selectedAppNo;
          
            let promise = axios.get(`${API_URL}/admission/getApplicationValidationStatus/${appNo}`)
                .then( (response) => {
                    if(response.data.status){
                        return true;
                    }else{
                        return false;
                    }
                }); 
            return promise;
        }

        validateApplication = () => {
            const formData = {
                appNo:this.state.selectedAppNo,
                userID: localStorage.getItem('user')
            };
            
            axios.post(`${API_URL}/admission/validateApplication`, formData)
                .then( (response) => {
                    if(response.data.status){
                        this.setState({
                            isApplicationValidated: true
                        }, () => {
                            this.getAllApplications(this.state.applicationFilter);
                            socket.emit('admission:getCurrentApplicationStats')
                        });
                    }
                });
        }
        
        handleCloseSuccessfulValidation = () => {
            this.setState({
                selectedAppNo: '',
                isApplicationValidated: false,
                selectedFile: null,
                selectedFileID: '6'
            }, () => {
                this.getListOfUploadedFiles(this.state.selectedAppNo);
            });
        }

        handleCloseSuccessfulInvalidApplication = () => {
            this.setState({
                selectedAppNo: '',
                isApplicationInvalidated: false,
                selectedFile: null,
                selectedFileID: '6'
            }, () => {
                this.getListOfUploadedFiles(this.state.selectedAppNo);
            });
        }

    getAnnualIncomeList = () => {
        axios.get(`${API_URL}/admission/getAnnualIncomeList`)
            .then( (response) => {
                this.setState({annualIncomeOptions: response.data});
                // coursesOptions = {...response.data};
                 //console.log(`annualIncome: ${this.state.annualIncomeOptions}`);
            });
    }

    checkLoggedInUser = () => {
        const loggedInUser = localStorage.getItem('user');
        
        if(loggedInUser){
            AUTH_HEADER = {
                headers: {
                    'Authorization': `${localStorage.getItem('apiToken')}`,
                    'Content-Type': 'application/json'
                }
            }
            // AUTH_HEADER = localStorage.getItem('apiToken');
            this.setState({
                username: loggedInUser.username,
                isUserLoggedIn: true,
                showLogin: false
            })
        }
    }

    onSuccess = (res) => {
        // //console.log(`[Login success] currentUser:${JSON.stringify(res.profileObj)}`);
        // //console.log(`[Login success] tokeObj:${JSON.stringify(res.tokenObj)}`);
        // //console.log(`[Login success] tokeId:${JSON.stringify(res.tokenId)}`);

        const email = res.profileObj.email;
        const tokenID = res.tokenId

        axios.post(`${API_URL}/admission/auth/google`,
            {
                email: email,
                tokenId: tokenID
            }
        )
        .then( (response) => {
            //console.log(`responseGoogle: ${JSON.stringify(response.data)}`);


            if(response.data.success){
                this.setState({
                    showLogin: false,
                    isUserLoggedIn: true,
                    userType: response.data.userType,
                    userProgramAccess: response.data.userProgramAccess,
                }, () => {

                    AUTH_HEADER = {
                        headers: {
                            'Authorization': `${response.data.token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                    
                    //console.log(`AUTH_HEADER:${JSON.stringify(AUTH_HEADER)}`);

                    localStorage.setItem('user', email);
                    localStorage.setItem('apiToken', response.data.token);
        
                });

                this.setState({
                    navBtnSelected: response.data.userType === 'dean' ? 'Rankings' : 'DocView'
                });
            }else{
                alert(response.data.message);
            }
        });

    }

    onFailure = (res) => {
        //console.log(`[Login failed] res:${JSON.stringify(res)}`);
    }

    componentDidMount(){
        this.checkLoggedInUser();

        if(this.state.isUserLoggedIn){
            this.getAllApplications(this.state.applicationFilter);
            this.getAnnualIncomeList();
        }
        

        socket = io(`${API_URL}`);
        
        socket.emit('admission:getCurrentlySelectedApps');
        socket.emit('admission:getCurrentAccessStats');
        socket.emit('admission:getCurrentApplicationStats');

        socket.on('currentlySelectedApplicant', (data) => {
            //console.log(`currSelAppClient: ${JSON.stringify(data)}`);
            const currSelectedApps = data.currentlySelectedApps;
            this.setState({
                allSelectedApplications: currSelectedApps
            });
        });

        socket.on('reloadListOfApplications', () => {
            this.getAllApplications(this.state.applicationFilter);
            socket.emit('admission:getCurrentApplicationStats');
        });

        socket.on('reloadAccessStats', (data) => {
            this.setState({
                accessStats: data
            }, () => {
                //console.log(`accessStats: ${JSON.stringify(this.state.accessStats)}`);
            })
        });

        socket.on('reloadApplicationStats', (data) => {
            this.setState({
                applicationStats: data
            }, () => {
                //console.log(`applicationStats: ${JSON.stringify(this.state.applicationStats)}`);
            })
        });

        
    }

    render(){
        const { classes } = this.props;

        const applicationList = this.state.allApplications.length > 0  ? this.state.allApplications.map( (key) => {
            let isSelected = false;
            for(const selectedApp of this.state.allSelectedApplications){
                if(selectedApp.appNo === key.AppNo && selectedApp.user !== localStorage.getItem('user')){
                    isSelected = true;
                    break;
                }
            }
            if(!isSelected){
                return <Button style={{width:"100%",fontSize:"12px"}} color="primary" id={key.AppNo} onClick={this.handleApplicantSelected} >{key.LastName},{key.FirstName}</Button>
            }
            
        }) : <Typography variant="subtitle1">No Available Data</Typography> ;

        const encodedGradesList = this.state.allApplications.length > 0  ? this.state.allApplications.map( (key) => {
            if(key.AppNo === this.state.selectedAppNo){
                return ( <React.Fragment>
                            <Typography variant="h6">English</Typography> 
                            <TextField label='Grade 9' id='grade9GradeEnglish' value={key.Eng_Gr9} />
                            <TextField label='Grade 10' id='grade10GradeEnglish' value={key.Eng_Gr10} />
                            <TextField label='Grade 11' id='grade11GradeEnglish' value={key.Eng_Gr11} />
                            <Typography variant="h6">Math</Typography> 
                            <TextField label='Grade 9' id='grade9GradeMath' value={key.Mat_Gr9} />
                            <TextField label='Grade 10' id='grade10GradeMath' value={key.Mat_Gr10} />
                            <TextField label='Grade 11' id='grade11GradeMath' value={key.Mat_Gr11} />
                            <Typography variant="h6">Science</Typography> 
                            <TextField label='Grade 9' id='grade9GradeScience' value={key.Sci_Gr9} />
                            <TextField label='Grade 10' id='grade10GradeScience' value={key.Sci_Gr10} />
                            <TextField label='Grade 11' id='grade11GradeScience' value={key.Sci_Gr11} />
                            <Typography variant="h6">GPA/GA</Typography> 
                            <TextField label='Grade 9' id='grade9GradeGPA' value={key.Grade_9} />
                            <TextField label='Grade 10' id='grade10GradeGPA' value={key.Grade_10} />
                            <TextField label='Grade 11' id='grade11GradeGPA' value={key.Grade_11} />
                        </React.Fragment>
                )
            }
        }) : <Typography variant="subtitle1">No Available Data</Typography> ;
            
        const encodedFAnnualIncome = this.state.allApplications.length > 0  ? this.state.allApplications.map( (key) => {
            if(key.AppNo === this.state.selectedAppNo){
                let actualIncomeBracket = null;
                for(const incomeBracket of this.state.annualIncomeOptions){
                    if(incomeBracket.income_id === key.Father_Income){
                        let formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'PHP'
                        });
                        actualIncomeBracket = `${formatter.format(incomeBracket.income_from)} - ${formatter.format(incomeBracket.income_to)}`;
            
                    }
                }
                return ( <React.Fragment>
                    <Typography>Father's Annual Income</Typography>    
                    <TextField fullWidth label='Annual Income' id='fParentAnnualIncome' value={actualIncomeBracket} />
    
                </React.Fragment>);
            } 
        }) : <Typography variant="subtitle1">No Available Data</Typography> ;

        const encodedMAnnualIncome = this.state.allApplications.length > 0  ? this.state.allApplications.map( (key) => {
            if(key.AppNo === this.state.selectedAppNo){
                let actualIncomeBracket = null;
                for(const incomeBracket of this.state.annualIncomeOptions){
                    if(incomeBracket.income_id === key.Mother_Income){
                        let formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'PHP'
                        });
                        actualIncomeBracket = `${formatter.format(incomeBracket.income_from)} - ${formatter.format(incomeBracket.income_to)}`;
                    }
                }
                return ( <React.Fragment>
                    <Typography>Mother's Annual Income</Typography>      
                    <TextField fullWidth label='Annual Income' id='mParentAnnualIncome' value={actualIncomeBracket} />
    
                </React.Fragment>);
            } 
        }) : <Typography variant="subtitle1">No Available Data</Typography> ;

        const submittedFilesList = this.state.listOfUploadedFiles.length > 0 ? this.state.listOfUploadedFiles.map( (key) => {
            let docName = this.getDocumentName(key.documentID);
            //console.log(`Uploaded File: ${JSON.stringify(key)}`);
            let verifiedMark = key.isVerified ? '(Verified)' : key.isInvalid ? '(Invalid)' : '(Unverified)';
            return ( <React.Fragment>
                <Grid item xs={4} sm={4}>
                    <ListItem  button id={key.documentID} onClick={this.handleFileTypeSelected}>
                        <ListItemIcon>
                            {key.isVerified ? <CheckCircleIcon style={{fill:"green"}} /> : null }
                        </ListItemIcon>
                        <ListItemText 
                            
                            primary={docName} 
                            secondary={key.isInvalid ? <span style={{color:"red"}}>{verifiedMark}</span> : verifiedMark} />
                    </ListItem>
                </Grid>
            </React.Fragment>)
        }): null ;

        const fileViewer = this.state.selectedFileType === 'doc' ? 
            <iframe src={this.state.selectedFile ? this.state.selectedFile.fileSrc : ''} height="1000px" width="90%"></iframe> 
            : 
            <Container maxWidth="md"><img src={this.state.selectedFile ? this.state.selectedFile.fileSrc : ''} width="400px" /></Container>

        const applicantInfo = this.state.selectedAppNo !== '' && this.state.allApplications.length > 0  ? this.state.allApplications.map( (key) => {
                    if(key.AppNo === this.state.selectedAppNo){
                        return ( <React.Fragment>
                            <TextField style={{width:"90%"}} label="AppNo" value={`${key.AppNo}`} />
                            <TextField style={{width:"90%"}} label="Name" value={`${key.LastName}, ${key.FirstName} ${key.MiddleName}`} />
                            <TextField style={{width:"90%"}} label="Birthdate" value={key.DateOfBirth} />
                            <TextField style={{width:"90%"}} label="Email Address" value={key.email} />
                            <TextField style={{width:"90%"}} label="Permanent Address" value={`${key.Perm_Street} ${key.Perm_Barangay}, ${key.Perm_TownCity}, ${key.Perm_Province}`} />
                            <TextField style={{width:"90%"}} label="Contact Number" value={key.TelNo} />
                            <TextField style={{width:"90%"}} label="Nationality" value={key.Nationality} />
                        </React.Fragment>)
                    }
                })
            : null
        
        const applicantScholasticInfo = this.state.selectedAppNo !== '' && this.state.allApplications.length > 0  ? this.state.allApplications.map( (key) => {
                if(key.AppNo === this.state.selectedAppNo){
                    return ( <React.Fragment>
                        <TextField style={{width:"90%"}} label="PassCode" value={`${key.pass_code}`} />
                        <TextField style={{width:"90%"}} label="Senior High School" value={key.SH_School} />
                        <TextField style={{width:"90%"}} label="SH Track" value={key.Track_Name} />
                        <TextField style={{width:"90%"}} label="SH Strand" value={key.Strand_Name} />
                        <TextField style={{width:"90%"}} label="Preferred Campus" value={key.Choice1_CampusName} />
                        <TextField style={{width:"90%"}} label="Preferred Course" value={key.Choice1_CourseName} />
                        <TextField style={{width:"90%"}} label="Preferred Major" value={key.Choice1_CourseMajorName ? key.Choice1_CourseMajorName : 'None'} />
                    </React.Fragment>)
                }
            })
        : null

        const buttonVerify = this.state.selectedFile ? ( this.state.selectedFile.isVerified ?
            <Button onClick={this.handleOpenConfirmRemoveVerification} color='secondary' variant='outlined' style={{marginRight:"5px"}} >Remove Verification</Button>
            :
            <Button onClick={this.handleOpenConfirmVerification} color='secondary' variant='outlined' style={{marginRight:"5px"}} >Verify</Button>
        ) : null 

        const buttonInvalidate = this.state.selectedFile ? ( !this.state.selectedFile.isInvalid ?  
            <Button onClick={this.handleOpenConfirmInvalidation} color='primary' variant='outlined' >Invalidate</Button>
            : null 
        ) : null 

        const selectedDocDetails = this.state.selectedFile ?  
            <Grid item xs={12} sm={12}> 
                <Typography variant="h6">{this.state.selectedDocName}</Typography>
                <Grid item >
                    <Typography variant="subtitle1" style={{display:"inline"}}>Status: {this.state.selectedFile.isVerified ? 'Verified' : this.state.selectedFile.isInvalid ? 'Invalid' : 'Unverified' }</Typography> 
                    <Typography variant="body1" style={{display:"inline",marginLeft:"10px",marginRight:"10px"}}>|</Typography>
                    {this.state.selectedFile.checkedBy ? 
                        <Typography variant="subtitle1" style={{display:"inline"}}>
                            {this.state.selectedFile.isVerified ? `Verified By:` : this.state.selectedFile.isInvalid ? `Invalidated By:` : `Verification Removed By:`} {this.state.selectedFile.checkedBy}
                        </Typography>
                    : null }

                    {this.state.selectedFile.remarks ? <Typography variant="subtitle1" style={{display:"block"}}>Remarks: {this.state.selectedFile.remarks}</Typography> : null} 
                </Grid>
            </Grid> : null
     
        return (
            <Container maxWidth="xl">
                <Grid item container align="right" >
                    <Grid item xs={12} sm={12}>
                        <Button onClick={this.handleUserLogout}>Log Out</Button>
                        {/* <GoogleLogout /> */}
                    </Grid>    
                </Grid>

                <Grid item xs={12} sm={12}>
                    {/* Menu Nav Container */}
                    <div>
                        <Grid item container>
                            <Grid item xs={12} sm={12} align="center" >
                                {this.state.userType !== 'dean' ? 
                                    <Button variant="outlined" color={this.state.navBtnSelected === 'DocView' ? 'primary' : ''} onClick={() => {this.handleNavBtnSelect('DocView')}}>DocView</Button>
                                    : null
                                }
                                <Button variant="outlined" color={this.state.navBtnSelected === 'Rankings' ? 'primary' : ''} onClick={() => {this.handleNavBtnSelect('Rankings')}}>Rankings</Button>
                                {/* <Button variant="outlined"><Link to="/docView">DocView</Link></Button>
                                <Button variant="outlined"><Link to="/rankings">Rankings</Link></Button> */}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                {/* Rankings Component */}
                {this.state.navBtnSelected === 'Rankings' ?
                ( 
                    <Grid item container xs={12} sm={12}>
                        <Rankings 
                            authHeader={AUTH_HEADER}
                            userProgramAccess={this.state.userProgramAccess}
                            userType={this.state.userType}
                        />
                    </Grid>
                ) : null }

                {this.state.navBtnSelected === 'DocView' && this.state.userType !== 'dean' ?
                (                     
                    <Grid item container xs={12} sm={12}>
                        {/* Sidebar List of Applications */}
                        <Grid item container xs={12} sm={2} style={{paddingRight:"10px"}}>
                            <div>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h6">List of Applications</Typography>
                                    <FormControl fullWidth>
                                        <InputLabel id="app-filter-label">Filter</InputLabel>
                                        <Select
                                            MenuProps={{ disableScrollLock: true}}
                                            labelId="app-filter-label"
                                            id="applicationFilter"
                                            value={this.state.applicationFilter}
                                            onChange={this.handleApplicationFilter}
                                        >
                                        <MenuItem value={1}>Pending Validation</MenuItem>
                                        <MenuItem value={2}>Validated</MenuItem>
                                        <MenuItem value={3}>Invalid</MenuItem>
                                        <MenuItem value={4}>All</MenuItem>
                                        </Select>

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} style={{maxHeight:"700px", overflowY:"scroll"}}>
                                    {applicationList}
                                </Grid>
                            </div>
                        </Grid>
                        
                        {/* Stats & App Details Container */}
                        <Grid item container xs={12} sm={10}>
                            
                            {/* Stats Container */}
                            <Grid item xs={12} sm={12}>
                                <div>
                                    <Grid item container>
                                        <Grid item xs={12} sm={7} style={{paddingLeft:"20px",backgroundColor:"#e2e2e2"}}>
                                            <Typography variant="h6">Applications Stats</Typography>
                                            <div>
                                                <Grid item container>
                                                    <Grid item xs={3} sm={3}>
                                                        <Typography>Total</Typography>
                                                        <Typography>{this.state.applicationStats ? this.state.applicationStats.total : null}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3}>
                                                        <Typography>Validated</Typography>
                                                        <Typography>{this.state.applicationStats ? this.state.applicationStats.validated : null}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3}>
                                                        <Typography>Pending </Typography>
                                                        <Typography>{this.state.applicationStats ? this.state.applicationStats.pending : null}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3}>
                                                        <Typography>Invalidated</Typography>
                                                        <Typography>{this.state.applicationStats ? this.state.applicationStats.invalid : null}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={5} style={{backgroundColor:"#e2e2e2"}}>
                                            <Typography variant="h6">Real-time Access Stats</Typography>
                                            <div>
                                                <Grid item container>
                                                    <Grid item xs={4} sm={4}>
                                                        <Typography>Filling Out</Typography>
                                                        <Typography>{this.state.accessStats ? this.state.accessStats.accessing : null}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4}>
                                                        <Typography>Reviewing</Typography>
                                                        <Typography>{this.state.accessStats ? this.state.accessStats.reviewing : null}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4}>
                                                        <Typography>Submitting</Typography>
                                                        <Typography>{this.state.accessStats ? this.state.accessStats.submitting : null}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            
                            {/* Application Details */}
                            {this.state.selectedAppNo ? 
                                (
                                <Grid item xs={12} sm={12} >
                                    <div>
                                        {/* Application Summary Section */}
                                        <Grid item container xs={12} sm={12} style={{marginLeft:"0px",marginTop:"10px"}}>
                                            {/* Applicant Info Summary */}
                                            <Grid item container xs={12} sm={7} style={{paddingLeft:"20px"}} >
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h6" style={{fontSize:"1.8rem",color:"maroon"}}>Applicant Info:</Typography>
                                                    <Typography variant="body" style={{backgroundColor:'#f4eded',fontFamily:`"Quicksand", "Roboto", "Helvetica", "Arial", sans-serif`}}>Previous Application Status: &nbsp;
                                                        <span style={{fontWeight:'bold',

                                                            color:`${(this.state.prevAppStatus === 'Validated' || this.state.prevAppStatus === 'Enlisted')  ? 'green' : (this.state.prevAppStatus === 'Invalidated' ? 'maroon' : 'black')}`}}>
                                                            {this.state.prevAppStatus}
                                                        </span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {applicantInfo}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {applicantScholasticInfo}
                                                </Grid>
                                                
                                            </Grid>

                                            {/* List of Submitted Files Section */}
                                            <Grid item xs={12} sm={5} >
                                                <Typography variant="h6" style={{fontSize:"1.8rem",color:"maroon"}}>Submitted Files:</Typography>
                                                <Grid item container xs={12} sm={12}> 
                                                    {submittedFilesList}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Document Details Section */}
                                        <Grid item container xs={12} sm={12} style={{marginTop:"30px"}}>
                                            {/* Document Details Section */}
                                            <Grid item container xs={12} sm={12} className={classes.docView} align="center">
                                                <Grid item container xs={12} sm={12} align="center">
                                                    {/* {this.state.selectedFile ? 
                                                        <DocumentViewer 
                                                            selectedDocName={this.state.selectedDocName}
                                                            selectedFile={this.state.selectedFile}     
                                                        />
                                                    : null } */}
                                                    {selectedDocDetails}
                                                    <Grid item xs={12} sm={4} style={{margin:"0 auto"}}>
                                                        {buttonVerify}
                                                        {buttonInvalidate}
                                                    </Grid>
                                                </Grid>

                                                {/* File Viewer */}
                                                <Grid item xs={12} sm={this.state.selectedDocType === 'file_form137' || this.state.selectedDocType === 'file_ToR'  || this.state.selectedDocType === 'file_incomeSource' ? 9 : 12}>
                                                    {fileViewer}
                                                </Grid>
                                                
                                                {/* Sidebar for Applicant's Encoded Grades */}
                                                <Grid item xs={12} sm={3}>
                                                
                                                    <Typography variant="h6">
                                                        {this.state.selectedDocType === 'file_form137' || this.state.selectedDocType === 'file_ToR' ? 'Encoded Grades' : ''}
                                                        {this.state.selectedDocType === 'file_incomeSource' ? `Income Source` : ''}
                                                        {/* {this.state.selectedDocType === 'file_mParentITR' ? `Mother's Annual Income` : ''} */}
                                                    </Typography>
                                                    {this.state.selectedDocType === 'file_form137' || this.state.selectedDocType === 'file_ToR' ? <EncodedGrades appNo={this.state.selectedAppNo} isTransferee={this.state.selectedAppNoIsTransferee} /> : ''}
                                                    {this.state.selectedDocType === 'file_incomeSource' ? <EncodedIncome appNo={this.state.selectedAppNo} /> : ''}
                                                    {/* {this.state.selectedDocType === 'file_incomeSource' ? [...encodedFAnnualIncome, ...encodedMAnnualIncome] : ''} */}
                                                    {/* {this.state.selectedDocType === 'file_mParentITR' ? encodedMAnnualIncome : ''} */}
                                                    
                                                </Grid>


                                            </Grid>
                                        </Grid>

                                    </div>
                                </Grid> 
                                )
                            : null }

                        </Grid>
                    </Grid>
                ) : null }
                
                {/* User Login Dialog */}
                <Dialog fullScreen open={this.state.showLogin}>
                    <DialogContent>
                        <Container maxWidth="lg" align="center">
                            <Grid item container xs={6} sm={6} spacing={1}>
                                {/* <Grid item xs={12} sm={12}>
                                    <TextField variant="outlined" onChange={this.changeHandler} id="username" label="username" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField type="password" variant="outlined" onChange={this.changeHandler} id="password" label="password" fullWidth/>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={12}>
                                    <Button onClick={this.handleUserLogin} variant="outlined" color="secondary">Log In</Button>
                                </Grid> */}
                            </Grid>
                            <Grid item container xs={6} sm={6} spacing={1} >
                                <Typography variant="h6" style={{color:"rgb(87, 87, 87)",fontSize:"3.25rem",width:"100%"}}>Hello there!</Typography>
                                <Typography variant="body" style={{fontSize:"1.30rem",width:"100%",fontFamily:'"Quicksand", "Roboto", "Helvetica", "Arial", sans-serif'}}>
                                    Please log in to get started.
                                </Typography>
                                <div style={{width:"100%",marginTop:"20px"}}>
                                    <GoogleLogin
                                        clientId={clientId}
                                        buttonText="Login Using Your USeP Google Account"
                                        onSuccess={this.onSuccess}
                                        onFailure={this.onFailure}
                                        cookiePolicy={'single_host_origin'}
                                        style={{ marginTop: '100px' }}
                                        isSignedIn={false}
                                    />  
                                </div>
                            </Grid>
                            
                        </Container>
                    </DialogContent>
                </Dialog>

                {/* Confirm File Verification Removal Dialog */}
                <Dialog open={this.state.hasClickedRemoveVerification} >
                    <DialogTitle>Confirm Undo Verification</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">
                            You are about to revoke this document's verification status.
                            Please click 'Confirm' to finalize this action. 
                            You may also add relevant descriptive remarks/comments in the text box below.
                        </Typography>
                        <TextareaAutosize id="verificationRemarks" onChange={this.changeHandler} rowsMin={8} style={{width:"100%"}} placeholder="Enter descriptive remarks"/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleUnverifySelectedFile}  color="primary">
                            Confirm
                        </Button>
                        <Button onClick={this.handleCloseConfirmUnverification}  color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Successful File Verification Removal Dialog */}
                <Dialog open={this.state.successfulFileRemoveVerification} >
                    <DialogTitle>Document Verification Revoked</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="h6" displayInline>The document's verification status has been revoked.</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSuccessfulRemoveVerification}  color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Confirm File Verification Dialog */}
                <Dialog open={this.state.hasClickedVerify} >
                    <DialogTitle>Confirm Document Verification</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" displayInline>
                            You are about to verify this document. 
                            Please click 'Confirm' to finalize this action.
                            You may also add relevant descriptive remarks/comments in the text box below.
                        </Typography>
                        <TextareaAutosize id="verificationRemarks" onChange={this.changeHandler} rowsMin={8} style={{width:"100%"}} placeholder="Enter descriptive remarks"/>
                        <DialogContentText>
                            
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleVerifySelectedFile}  color="primary">
                            Confirm
                        </Button>
                        <Button onClick={this.handleCloseConfirmVerification}  color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Successful File Verification Dialog */}
                <Dialog open={this.state.successfulFileVerification} >
                    <DialogTitle>Document Verification Success</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="h6" displayInline>The document has been tagged as Verified.</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSuccessfulVerification}  color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                
                {/* Confirm File Invalidation Dialog */}
                <Dialog open={this.state.hasClickedInvalidate} >
                    <DialogTitle>Confirm Document Verification</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">
                            You are about to tag this document as 'Invalid'.
                            Please click 'Confirm' to finalize this action.
                            You may also add relevant descriptive remarks/comments in the text box below.
                        </Typography>
                        <TextareaAutosize id="verificationRemarks" onChange={this.changeHandler} rowsMin={8} style={{width:"100%"}} placeholder="Enter descriptive remarks"/>
                        <DialogContentText>
                            
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleInvalidateSelectedFile}  color="primary">
                            Confirm
                        </Button>
                        <Button onClick={this.handleCloseConfirmInvalidation}  color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                
                {/* Successful File Invalidation Dialog */}
                <Dialog open={this.state.successfulFileInvalidation} >
                    <DialogTitle>Document Invalidation Success</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">The document has been tagged as Invalid.</Typography>
                        <DialogContentText>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSuccessfulInvalidation}  color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Successful Application Validation Dialog */}
                <Dialog open={this.state.isApplicationValidated} >
                    <DialogTitle>Application Validated</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">
                            All required documents has been verified.
                            Application No. {this.state.selectedAppNo} is now 
                            officially validated.
                        </Typography>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSuccessfulValidation}  color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Successful Application InValidation Dialog */}
                <Dialog open={this.state.isApplicationInvalidated} >
                    <DialogTitle>Invalid Application</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">
                            Application No. {this.state.selectedAppNo} has been tagged as invalid
                            due to an invalid document submission.
                        </Typography>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSuccessfulInvalidApplication}  color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Successful Application Invalid Status Removal Dialog */}
                <Dialog open={this.state.isAppInvalidStatusRemovedDialogOpen} >
                    <DialogTitle>Application Invalid Status Removed</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">
                            Application No. {this.state.selectedAppNo}'s invalid status has been revoked.
                        </Typography>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSuccessfulInvalidStatusRemoval}  color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <Backdrop open={this.state.isLoadingFile} style={{zIndex:"9000"}}>
                    <CircularProgress style={{zIndex:"9000"}}/> 
                </Backdrop > 

            </Container>
    
        );
    }
}

export default withStyles(styles) (DocView)
