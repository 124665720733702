
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import {
    Dialog,
    DialogContent,
    Button,
    Divider,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    DialogActions,
    DialogTitle,
    DialogContentText
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import passportPhotoSample from './../../passport-photo-sample.png'    ; 
import idPhotoSample from './../../id-photo-sample.png' ; 

// actions
import { 
    setIsFilipinoAction, 
    setApplicantEntryType,
    setIsK12Graduate,
    setIsVocationalCourseGraduate 
} from "../../redux/actions/applicant";


import { HOMEPAGE_URL } from './../config';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const PreRegQuestions = () => {

    let history = useHistory();

    const [isFilipino, setIsFilipino] = useState(null);
    const [applicationType, setApplicationType] = useState();
    const [viewSamplePhoto, setViewSamplePhoto] = useState(false);     
    const [selectedSamplePhoto, setSelectedSamplePhoto] = useState(null);     

    const applicant  = useSelector(state => state.applicant);

    const onConfirmFilipino = (data) => {
        setIsFilipinoAction(data)
    }

    const onSelectEntryType = (data) => {
        setApplicantEntryType(data)
    }

    const onConfirmK12Graduate = (data) => {
        setIsK12Graduate(data)
    }

    const onConfirmVocationalCourseGraduate = (data) => {
        setIsVocationalCourseGraduate(data)
    }

    const handleViewPhotoSample = (type) => {
        if(type === 'withNameTag'){
            setViewSamplePhoto(true);
            setSelectedSamplePhoto('withNameTag');
        }else{
            setViewSamplePhoto(true);
            setSelectedSamplePhoto('withIDCard');
        }
    }

    useEffect(() => {
        
        if(!applicant.courseCategoryID){
            window.location.replace(`${HOMEPAGE_URL}`);
        }
        
    }, [])

    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
            >
        
                {/* <AppBar sx={{ position: 'relative' }} >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => alert('Close')}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Confirm</Typography>
                        <Button autoFocus color="inherit" onClick={() => alert('Saved')}>
                            NEXT
                        </Button>
                    </Toolbar>
                </AppBar> */}
                <DialogContent>
                    {/* <Typography variant="h1">Hello, {applicant.isFilipino}</Typography> */}
                    <Grid container spacing={2}  >
                        <Grid item xs={12}>
                            <Typography>Are you a Filipino citizen?</Typography>
                            <Button
                                variant={applicant.isFilipino === 'Yes' ? "contained" : "outlined"  } 
                                color={applicant.isFilipino === 'Yes' ? "secondary" : "neutral"  } 
                                // style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onConfirmFilipino('Yes')} >Yes</Button> 
                            <Button 
                                variant={applicant.isFilipino === 'No' ? "contained" : "outlined"  } 
                                color={applicant.isFilipino === 'No' ? "secondary" : "neutral"  } 
                                // style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onConfirmFilipino('No')} >No</Button> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Are you applying as an incoming First Year student or a Transferee?</Typography>
                            <Button
                                variant={applicant.applicantEntryTypeID === 1 ? "contained" : "outlined"  } 
                                color={applicant.applicantEntryTypeID === 1 ? "secondary" : "neutral"  }  
                                // style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onSelectEntryType(1) } >First Year</Button> 
                            <Button 
                                variant={applicant.applicantEntryTypeID === 2 ? "contained" : "outlined"  } 
                                color={applicant.applicantEntryTypeID === 2 ? "secondary" : "neutral"  } 
                                // style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onSelectEntryType(2) } >Transferee</Button> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Please select your high school curriculum</Typography>
                            <Button
                                variant={applicant.isK12Graduate === 1 ? "contained" : "outlined"  } 
                                color={applicant.isK12Graduate === 1 ? "secondary" : "neutral"  }  
                                // style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onConfirmK12Graduate(1) } >K-12 Curriculum</Button> 
                            <Button 
                                variant={applicant.isK12Graduate === 0 ? "contained" : "outlined"  } 
                                color={applicant.isK12Graduate === 0 ? "secondary" : "neutral"  } 
                                // style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onConfirmK12Graduate(0) } >Old Curriculum</Button> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Are you a graduate of a Vocational/Trade course?</Typography>
                            <Button
                                variant={applicant.isVocationalCourseGraduate === 1 ? "contained" : "outlined"  } 
                                color={applicant.isVocationalCourseGraduate === 1 ? "secondary" : "neutral"  }  
                                // style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onConfirmVocationalCourseGraduate(1) } >Yes</Button> 
                            <Button 
                                variant={applicant.isVocationalCourseGraduate === 0 ? "contained" : "outlined"  } 
                                color={applicant.isVocationalCourseGraduate === 0 ? "secondary" : "neutral"  } 
                                // style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onConfirmVocationalCourseGraduate(0) } >No</Button> 
                        </Grid>


                        {/* List of Documentary Requirements */}
                        <Grid style={{padding:"5px"}} item xs={12}>
                            <Typography style={{marginBottom:"10px"}}>
                                For a better application experience, please make sure you already have the following
                                required documents before proceeding:
                            </Typography>
                            <List component="nav">
                                <ListItem button>
                                <ListItemText primary="1. Scanned certified true copy of High School Permanent Record in Grade 11 (for Incoming First Year Students) OR Transcript of Records with GWA (for Transferees)" secondary="Required file type: PDF  |  Max file size: 2MB" />
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="2. Passport size Digital Photo of you holding a handwritten Name Tag below your face" secondary="Required file type: JPG/PNG  |  Max file size: 2MB" />
                                    <Button onClick={() => {handleViewPhotoSample('withNameTag')} }>View Sample</Button>
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="3. Digital Photo of you holding a valid ID (Barangay, Postal, Voter's, or School ID) card beside your face. " secondary="Required file type: JPG/PNG   |  Max file size: 2MB" />
                                    <Button onClick={() => {handleViewPhotoSample('withIDCard')} } >View Sample</Button>
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="4. Digital Photo of your signature" secondary="Required file type: JPG/PNG   |  Max file size: 2MB"/>
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="5.  Parents' or Guardian's or Your Own Income Tax Return document. Alternatively, you may submit a Certificate of Low Income document from the barangay
                                    if the Income Tax Return document is not applicable. If there are two ITR/Certificate of Low Income documents applicable, (e.g. both parents have ITR/Cert of Low Income) please combine all the documents into one single PDF file." secondary="Required file type: PDF   |  Max file size: 2MB" />
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="6. NCIP-issued Certificate of Membership to the Indigenous Peoples (for members of the Indigenous Peoples)" secondary="Required file type: PDF   |  Max file size: 2MB"/>
                                </ListItem>
                            </List>
                            <Typography variant="body1" align="justify">
                                If you have a admission-related concerns, please email us at <a style={{color:"maroon",fontWeight:"bold"}}>admissionsunit@usep.edu.ph</a>. 
                                Should you encounter technical issues in using this website, please email <a style={{color:"maroon",fontWeight:"bold"}}>sdmd@usep.edu.ph</a>.
                            </Typography>
                            <Typography style={{marginTop:"10px",marginBottom:"10px"}}>
                                If you are ready, please hit 'Proceed' to continue.
                            </Typography>
                        </Grid>
                

                    </Grid> 
                </DialogContent>

                <DialogActions>
                    <Button 
                        style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                        onClick={() => {history.push('/')} } >Cancel</Button> 
                    <Button 
                        disabled = {!applicant.isFilipino || !applicant.applicantEntryTypeID ? true : false}
                        style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                        onClick={() => {
                            applicant.isFilipino === 'Yes' ? history.push('/dataPrivacyAgreement') : history.push('/foreignApplicantDetails')
                        } }
                        
                        >Proceed</Button> 
                </DialogActions>
            </Dialog>

            {/* // View Sample Photo Modal */}
            <Dialog maxWidth="lg" onClose={() => {setViewSamplePhoto(false) }} open={viewSamplePhoto} >
                <DialogTitle>{selectedSamplePhoto === 'withNameTag' ? "Passport Size Photo w/ Name Tag" : "Photo w/ ID Card" }</DialogTitle>
                <DialogContent style={{textAlign:"center"}}>
                    <img src={selectedSamplePhoto === 'withNameTag' ? passportPhotoSample : idPhotoSample } 
                        width={selectedSamplePhoto === 'withNameTag' ? "250" : "350" } />
                    <DialogContentText>
                        {selectedSamplePhoto === 'withNameTag' ? 
                            "Please ensure that your name is legible." : 
                            "Please ensure that your face and name in the ID card are recognizable in the uploaded photo." }
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setViewSamplePhoto(false) }}  >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
};

export default PreRegQuestions;