import {applyMiddleware, createStore, compose} from "redux";
// import {composeWithDevTools} from "redux-devtools-extension";
// import {createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync} from 'redux-state-sync'
// import {persistReducer, persistStore} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from './reducers';


// const middleware = [createStateSyncMiddleware(stateSyncConfig),]
// let reducers = persistReducer(persistLocalStorageConfig, withReduxStateSync(rootReducer))

// export const store = createStore(
//     reducers, {},
//     CONFIG.ENV_DEV ? composeWithDevTools(applyMiddleware(...middleware)) :
//         applyMiddleware(...middleware)
// );


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer, 
    composeEnhancers() 
);


// initStateWithPrevTab(store)
// export const persistor = persistStore(store);
