export const CONFIG = {

    // URLs
    // URL_API: 'https://api.usep.edu.ph/',
    URL_API: 'https://admissionapi.usep.edu.ph',
    // URL_API: 'http://apilocal.usep.edu.ph/',
    URL_USeP_Data_Privacy: 'https://www.usep.edu.ph/usep-data-privacy-statement/',
    URL_LBP_LINKBIZ: 'https://www.lbp-eservices.com/egps/portal/index.jsp',

    // email address
    EMAIL_KMD: 'kmd@usep.edu.ph',

    // envs
    ENV_PRODUCTION: true,
    ENV_STAGING: false,
    ENV_DEV: false,

    // system modes
    SYSTEM_UPGRADE_MODE: false,
    SYSTEM_MAINTENANCE_MODE: false,

    // system notice
    SYSTEM_UPGRADE_NOTICE: false,
    SYSTEM_UPGRADE_NOTICE_DATE: 'April 20, 2020',
    SYSTEM_UPGRADE_NOTICE_TIME: '12:00 AM',
    SYSTEM_MAINTENANCE_NOTICE: false,
    SYSTEM_MAINTENANCE_NOTICE_DATE: 'April 20, 2020',
    SYSTEM_MAINTENANCE_NOTICE_TIME: '12:00 AM',

    // CREDENTIALS BY CAMPUS
    CAMPUS_CREDENTIALS: {
        obrero: {
            bankName: 'Land Bank of the Philippines',
            bankAccountName: 'University of Southeastern Philippines - Evening Program',
            bankAccountNumber: '0162-1051-75',
            paymentEmail: 'payment@usep.edu.ph',
            inquiryEmail: 'payment@usep.edu.ph',
            studentAccountsEmail: 'studentaccounts_obrero@usep.edu.ph',
            inquiryMobile: '09398895917 (Smart)',
            concernsEmail: 'portal-obrero@usep.edu.ph'
        },
        mintal: {
            bankName: 'Land Bank of the Philippines',
            bankAccountName: 'University of Southeastern Philippines - Evening Program',
            bankAccountNumber: '0162-1051-75',
            paymentEmail: 'payment@usep.edu.ph',
            inquiryEmail: 'usepmintal_studentaccounts@usep.edu.ph',
            concernsEmail: 'portal-mintal@usep.edu.ph'
        },
        tagum: {
            bankName: 'Land Bank of the Philippines',
            bankAccountName: 'USEP-TAGUM CITY-OSTF',
            bankAccountNumber: '0342-1110-68',
            paymentEmail: 'payment.tagummabini@usep.edu.ph',
            inquiryEmail: 'payment.tagummabini@usep.edu.ph',
            concernsEmail: 'portal-tagum-mabini@usep.edu.ph'
        },
        mabini: {
            bankName: 'Land Bank of the Philippines',
            bankAccountName: 'USEP-TAGUM CITY-OSTF',
            bankAccountNumber: '0342-1110-68',
            paymentEmail: 'payment.tagummabini@usep.edu.ph',
            inquiryEmail: 'payment.tagummabini@usep.edu.ph',
            concernsEmail: 'portal-tagum-mabini@usep.edu.ph'
        },
    },

}

