import {store} from "../index";

// import applicant from './applicant';

// const allActions = {
//     applicant
// }

// export default allActions;

export const dispatch = (type, payload) => {
    const {dispatch} = store
    dispatch({type, payload})
}

// export const getState = () => {
//     const {getState} = store
//     return getState()
// }