const init = {
    testingCenters: null,
    testingDates: null,
    testingTimes: null,
    testingRooms: null,
    activeTermID: null,


}

const admissionTest = (state = init, action) => {
    switch (action.type) {
        case 'TESTING_CENTERS_SET':
            return {
                ...state,
                testingCenters: action.payload
            }
        case 'TESTING_DATES_SET':
            return {
                ...state,
                testingDates: action.payload
            }
        case 'TESTING_TIMES_SET':
            return {
                ...state,
                testingTimes: action.payload
            }
        case 'TESTING_ROOMS_SET':
            return {
                ...state,
                testingRooms: action.payload
            }
        case 'ACTIVE_TERM_ID_SET':
            return {
                ...state,
                activeTermID: action.payload
            }
        default:
            return state
    }
}


export default admissionTest;