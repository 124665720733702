import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { 
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Image,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    CircularProgress,
    Backdrop,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slide
} from '@material-ui/core';


// actions
import { 
    setForeignApplicantDetails,
    setForeignLastName,
    setForeignFirstName,
    setForeignMiddleName,
    setForeignSuffix,
    setForeignOtherName,
    setForeignPassportNumber,
    setForeignPassportPlaceIssued,
    setForeignPassportDateIssued,
    setForeignPassportExpiryDate,

} from "../../redux/actions/applicant";

import { HOMEPAGE_URL } from './../config';

// styling
const styles = theme => ({
    formHeader: {
        fontFamily: `"Quicksand", sans-serif`,
        fontWeight: 500,
        color:  `#d58a13`, //"#707070",
        marginTop: `15px`,
        marginBottom: `20px`

    },
    confirmSubmissionMsg: {
        fontFamily: `"Quicksand", "sans-serif"`,
        fontSize: 18,
        fontWeight: 400,
        color: 'black'
    },
    txtFields: {
        margin: theme.spacing(1),
        width: `95%`
    },
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ForeignApplicantDetails = (props) => {

    let history = useHistory();

    const { classes } = props;

    const applicant = useSelector(state => state.applicant);

    const [ fLastName, setFLastName ] = useState(null);
    const [ fFirstName, setFFirstName ] = useState(null);
    const [ fMiddleName, setFMiddleName ] = useState(null);
    const [ fSuffix, setFSuffix ] = useState(null);
    const [ fOtherName, setFOtherName ] = useState(null);

    const [ fPassportNumber, setFPassportNumber ] = useState(null);
    const [ fPassportPlaceIssued, setFPassportPlaceIssued ] = useState(null);
    const [ fPassportDateIssued, setFPassportDateIssued ] = useState(null);
    const [ fPassportExpiryDate, setFPassportExpiryDate ] = useState(null);

    const [ isFormValid, setIsFormValid ] = useState(false);

    useEffect( () => {
        // alert (`data: ${applicant.foreignLastName}`)
        if(!applicant.courseCategoryID){
            window.location.replace(`${HOMEPAGE_URL}`);
        }
    }, [])


    const changeHandler = (e) => {
        if(e.target.id === "fLastName"){
            // setFLastName(e.target.value);
            setForeignLastName(e.target.value);
            console.log(`fLastName: ${applicant.foreignLastName}`)
        }else if(e.target.id === "fFirstName"){
            // setFFirstName(e.target.value);
            setForeignFirstName(e.target.value);
            console.log(`fFirstName: ${applicant.foreignFirstName}`)
        }else if(e.target.id === "fMiddleName"){
            // setFMiddleName(e.target.value);
            setForeignMiddleName(e.target.value);
        }else if(e.target.id === "fSuffix"){
            // setFSuffix(e.target.value);
            setForeignSuffix(e.target.value);
        }else if(e.target.id === "fOtherName"){
            // setFOtherName(e.target.value);
            setForeignOtherName(e.target.value);
        }else if(e.target.id === "fPassportNumber"){
            // setFPassportNumber(e.target.value);
            setForeignPassportNumber(e.target.value);
            console.log(`fPassportNumber: ${applicant.foreignPassportNumber}`)
        }else if(e.target.id === "fPassportPlaceIssued"){
            // setFPassportPlaceIssued(e.target.value);
            setForeignPassportPlaceIssued(e.target.value);
            console.log(`fPassportPlaceIssued: ${applicant.foreignPassportPlaceIssued}`)
        }else if(e.target.id === "fPassportDateIssued"){
            // setFPassportDateIssued(e.target.value);
            setForeignPassportDateIssued(e.target.value);
            console.log(`fPassportDateIssued: ${applicant.foreignPassportDateIssued}`)
        }else if(e.target.id === "fPassportExpiryDate"){
            // setFPassportExpiryDate(e.target.value);
            setForeignPassportExpiryDate(e.target.value);
            console.log(`fPassportExpiryDate: ${applicant.foreignPassportExpiryDate}`)
        }

        
        updateFormValidation();
    }

    const submitForeignDetails = () => {
        const foreignDetails = {

        }
    }

    const updateFormValidation = () => {
        if( !applicant.foreignLastName || !applicant.foreignFirstName || !applicant.foreignPassportNumber 
            || !applicant.foreignPassportPlaceIssued || !applicant.foreignPassportDateIssued  || !applicant.foreignPassportExpiryDate ){
            setIsFormValid(false);
        }else{
            setIsFormValid(true);
        }

        console.log()
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={true}
                TransitionComponent={Transition}
            >
                <DialogContent>
                    <Grid item container xs={12} sm={12}>
                        <Grid item xs={12} spacing={2} sm={12} style={{margin:"25px auto"}} alignContent="center" alignItems="center" align="center">
                            <Typography variant="h6" fullWidth>Please enter your full name as written in your native language's alphabet</Typography>
                            <TextField 
                                id="fLastName"
                                name="fLastName"
                                label="Last Name"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignLastName}
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.txtFields}
                            />
                            <TextField 
                                id="fFirstName"
                                name="fFirstName"
                                label="First Name"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignFirstName}
                                fullWidth
                                variant="outlined"
                                required   
                                className={classes.txtFields}
                            />
                            <TextField 
                                id="fMiddleName"
                                name="fMiddleName"
                                label="Middle Name"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignMiddleName}
                                fullWidth
                                variant="outlined"
                                required   
                                className={classes.txtFields}
                            />
                            <TextField 
                                id="fSuffix"
                                name="fSuffix"
                                label="Suffix"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignSuffix}
                                fullWidth
                                variant="outlined"
                                // required   
                                className={classes.txtFields}
                            />
                            <TextField 
                                id="fOtherNames"
                                name="fOtherNames"
                                label="Other Name"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignOtherName}
                                fullWidth
                                variant="outlined"
                                // required   
                                className={classes.txtFields}
                            />
                        </Grid>
                        <Grid item xs={12} spacing={2} sm={12} style={{margin:"25px auto"}} alignContent="center" alignItems="center" align="center">
                            <Typography variant="h6" fullWidth>Please enter your passport details.</Typography>
                            <TextField 
                                id="fPassportNumber"
                                name="fPassportNumber"
                                label="Passport Number"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignPassportNumber}
                                fullWidth
                                variant="outlined"
                                required
                                className={classes.txtFields}
                            />
                            <TextField 
                                id="fPassportPlaceIssued"
                                name="fPassportPlaceIssued"
                                label="Place of Issue"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignPassportPlaceIssued}
                                fullWidth
                                variant="outlined"
                                required   
                                className={classes.txtFields}
                            />
                            <TextField 
                                id="fPassportDateIssued"
                                name="fPassportDateIssued"
                                label="Date Issued"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignPassportDateIssued}
                                fullWidth
                                variant="outlined"
                                required   
                                className={classes.txtFields}
                            />
                            <TextField 
                                id="fPassportExpiryDate"
                                name="fPassportExpiryDate"
                                label="Expiry Date"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={applicant.foreignPassportExpiryDate}
                                fullWidth
                                variant="outlined"
                                required   
                                className={classes.txtFields}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12} xm={12} style={{width:'60%'}} alignContent="center" alignItems="center" align="center" >
                        <Button 
                            style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                            onClick={() => {history.push('/')} } >Cancel</Button> 
                        <Button
                            disabled = {!applicant.foreignLastName || !applicant.foreignFirstName || !applicant.foreignPassportNumber 
                                || !applicant.foreignPassportPlaceIssued || !applicant.foreignPassportDateIssued  || !applicant.foreignPassportExpiryDate ? true : false}
                            style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}}
                            onClick={() => {history.push('/dataPrivacyAgreement')} }
                        >
                            Proceed
                        </Button>
                    </Grid>
                </DialogActions>

            </Dialog>
        </div>
    );
};

export default withStyles(styles)(ForeignApplicantDetails);