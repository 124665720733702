import React, {Component} from 'react';

import { 
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Image,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    CircularProgress,
    Backdrop,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';

import ApplicationResult from './ApplicationResult';
import ListOfProgramsWithSlots from './ListOfProgramsWithSlots';
import ForgotAccountDialog from './ForgotAccountDialog';

import axios from 'axios';
import io from 'socket.io-client';
import { API_URL } from './config';
import { CURR_TERMID } from './config';

let socket = null;

const styles = theme => ({
    formHeader: {
        fontFamily: `"Quicksand", sans-serif`,
        fontWeight: 500,
        color:  `#d58a13`, //"#707070",
        marginTop: `15px`,
        marginBottom: `20px`

    },
    confirmSubmissionMsg: {
        fontFamily: `"Quicksand", "sans-serif"`,
        fontSize: 18,
        fontWeight: 400,
        color: 'black'
    },
    txtFields: {
        margin: theme.spacing(1),
        width: `95%`
    },
  
    
});

class ResultChecker extends Component{

    state = {
        appNo: '',
        passCode: '',
        applicantNo: '',
        applicantTypeID: 0,
        applicantStatus: '',
        applicantProgram: '',
        applicantMajor: '',
        applicantName: '',
        applicantCollege: '',
        applicantCampus: '',
        applicantIsEnlisted: '',
        applicantProfilePhoto: null,
        confirmPassCode: '',
        confirmPassCodeIsValid: false,

        listOfProgramsWithSlots: null,
        hasClickedConfirmBtn: false,
        hasConfirmedSlot: false,
        hasClickedSelectOtherProgramBtn: false,
        hasConfirmedSlotToOtherProgram: false,
        hasClickedForgotAccount: false,
        displayProgramWithAvailableSlots: false,

        submitting: false,
        loadingProgramWithAvailableSlots: false,
        // forgotAccountMiddleName: '',
        // forgotAccountBirthdate: '',
        // forgotAccountEmail: '',
        // forgotAccountMobileNumber: '',

        resultsCheckingPeriod: 0 // 1 = first week for confirmation of qualifiers, 2 = 2nd week for assumption of vacated slots by waitlisted

    }

    changeHandler = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    getProfilePhoto = () => {
        const application = {
            appNo: this.state.appNo,
            passCode: this.state.passCode,
            fileID: 6
        };
        axios.post(`${API_URL}/admission/getUploadedFileByPasscode`,
            application
        )
        .then( (response) => {
            if(response.data){
                this.setState({
                    applicantProfilePhoto: response.data
                });
            }
        });
    }

    handleSelectAvailProgramClick = (program) => {
        this.enlistApplicantToOtherProgram(program);
    }

    enlistApplicantToOtherProgram = (program) => {
        const application = {
            appNo: this.state.appNo,
            passCode: this.state.passCode,
            program: program
        };

        axios.post(`${API_URL}/admission/enlistApplicantToOtherProgram`,
            application
        )
        .then( (response) => {
            // console.log(`ENLIST_OTHER_PROG_RESULT: ${JSON.stringify(response.data)}`);

            if(response.data.success){
                // call checkApplicationResult again to reflect the change in applicant program enlistment
                this.checkApplicationResult();
                this.logResultCheckerAction('waitlistedToOtherProgView');
              
            }else{
                alert(response.data.msg);
                // fetch list of programs w/ available slots to remove already full programs
                this.getListOfProgramsWithSlots();
            }
        })
        .catch( (err) => {
            console.log(JSON.stringify(err));
            alert(`Something went wrong.`);
        })
    }

    getListOfProgramsWithSlots = () => {
        this.setState({
            loadingProgramWithAvailableSlots: true
        })

        axios.get(`${API_URL}/admission/getProgramsWithAvailableSlots/${CURR_TERMID}`)
        .then( (response) => {
            this.setState({
                loadingProgramWithAvailableSlots: false
            })
            if(response.data){
                console.log(`PROG_WITH_SLOTS:${JSON.stringify(response.data)}`);
                this.setState({
                    listOfProgramsWithSlots: response.data
                }, () => {
                    this.setState({
                        displayProgramWithAvailableSlots: true
                    })
                });
            }
        })
    }

    getForgottenAccount = (applicant) => {
        const payload = {
            ...applicant,
            termID: CURR_TERMID
        }

        console.log(`FORGOT_ACCOUNT_PAYLOAD: ${JSON.stringify(payload)}`);

        axios.post(`${API_URL}/admission/getForgottenAccount`,
            payload
        )
        .then( (response) => {
            console.log(`FORGOT_ACCOUNT_RESPONSE: ${JSON.stringify(response.data)}`);
            if(response.data.found){   
                alert(`Application Found. \n Application No: ${response.data.appNo} \n Passcode: ${response.data.passCode}`);
            }else{
                alert(`Application Not Found. Please make sure you entered the correct application details`);
            }
        })
        .catch( (err) => {
            console.log(`FORGOT_ACCOUNT_ERR:${JSON.stringify(err)}`);
        })
    }

    checkApplicationResult = () => {
        const application = {
            appNo: this.state.appNo,
            passCode: this.state.passCode
        };

        this.setState({
            submitting: true
        })

        // this.getListOfProgramsWithSlots();
        this.getResultsCheckingPeriod();

        axios.post(`${API_URL}/admission/checkApplicationResult`,
            application
        )
        .then( (response) => {
            // console.log(`APP_RESULT: ${JSON.stringify(response.data)}`);
            this.setState({
                submitting: false
            })

            if(response.data){
                let applicationResult = response.data;

                this.getProfilePhoto();

                if(applicationResult.Status === 'Waitlisted'){
                    this.logResultCheckerAction('waitlistedView');
                    this.getListOfProgramsWithSlots();
                }
                if(applicationResult.Status === 'Qualified'){
                    this.logResultCheckerAction('qualifierView');
                    this.setState({
                        displayProgramWithAvailableSlots: false
                    })
                }
                if(applicationResult.Status === 'NotQualified'){
                    this.logResultCheckerAction('nonQualifier View');
                    this.setState({
                        displayProgramWithAvailableSlots: false
                    })
                }

                if(applicationResult.Status === 'Qualified' || applicationResult.Status === 'Waitlisted' || applicationResult.Status === 'WaivedSlot'){
                    this.setState({
                        applicantNo: applicationResult.AppNo,
                        applicantStatus: applicationResult.Status,
                        applicantName: applicationResult.Applicant,
                        applicantProgram: applicationResult.QualifiedCourse,
                        applicantMajor: applicationResult.QualifiedMajor,
                        applicantCollege: applicationResult.QualifiedCollege,
                        applicantCampus: applicationResult.QualifiedCampus,
                        applicantIsEnlisted: applicationResult.IsEnlisted
                    })
                }
                else if(applicationResult.Status === 'NotQualified'){
                    this.setState({
                        applicantTypeID: applicationResult.ApplyTypeID,
                        applicantNo: applicationResult.AppNo,
                        applicantStatus: applicationResult.Status,
                        applicantName: applicationResult.Applicant,
                        applicantProgram: applicationResult.AppliedCourse,
                        applicantMajor: applicationResult.AppliedMajor,
                        applicantCollege: applicationResult.AppliedCollege,
                        applicantCampus: applicationResult.AppliedCampus,
                        applicantIsEnlisted: false
                    })
                }
                else if(applicationResult.Status === 'NotFound'){
                    this.setState({
                        displayProgramWithAvailableSlots: false
                    })
                    this.setState({
                        applicantNo: applicationResult.AppNo,
                        applicantStatus: applicationResult.Status,
                        applicantName: applicationResult.Applicant,
                        applicantProgram: applicationResult.AppliedCourse,
                        applicantMajor: applicationResult.AppliedMajor,
                        applicantCollege: applicationResult.AppliedCollege,
                        applicantCampus: applicationResult.AppliedCampus,
                        applicantIsEnlisted: false
                    })
                }
                
            }
        })
    }

    handleForgotAccountBtnClicked = () => {
        this.setState({
            hasClickedForgotAccount: true,
        })  
    }

    handleForgotAccountCancelBtnClicked = () => {
        this.setState({
            hasClickedForgotAccount: false,
        })  
    }

    handleCancelConfirmDialog = () => {
        this.setState({
            hasClickedConfirmBtn: false
        });
    }

    handleConfirmBtnClick = () => {
        this.setState({
            hasClickedConfirmBtn: true
        })
    }

    handleConfirmBtnDialogClick = () => {
        const application = {
            appNo: this.state.appNo,
            passCode: this.state.passCode
        };

        axios.post(`${API_URL}/admission/enlistApplicant`,
            application
        )
        .then( (response) => {
            console.log(`ENLIST_RESULT: ${JSON.stringify(response.data)}`);

            if(response.data){
                this.setState({
                    hasClickedConfirmBtn: false,
                }, () => {
                    this.checkApplicationResult();
                })

            }
        })
    }

    getResultsCheckingPeriod = () => {
        axios.get(`${API_URL}/admission/checkResultsCheckingPeriod`)
        .then( (response) => {
            if(response.data){
                this.setState({
                    resultsCheckingPeriod: response.data.period
                });
            }
        })
        .catch( (err) => {
            console.log(JSON.stringify(err));
            alert(`Something went wrong.`);
        })
    }

    logResultCheckerAction = (action) => {
        const application = {
            appNo: this.state.appNo,
            passCode: this.state.passCode,
            action: action
        };

        axios.post(`${API_URL}/admission/logResultCheckerAction`,
            application
        )
        .then( (response) => {
            console.log(`logUserRespone: ${JSON.stringify(response.data)}`);
        })
        .catch( (err) => {
            console.log(`logUserError: ${JSON.stringify(err)}`);
        })
    }

    componentDidMount(){
        this.getResultsCheckingPeriod();
        this.logResultCheckerAction('pageView');
    }

    render() {

        const { classes } = this.props;

        return(
            <Container maxWidth="md">
                {/* Form Fields */}
                <Grid item container xs={12} sm={12}>
                    <Grid item xs={12} spacing={2} sm={6} style={{margin:"100px auto"}} alignContent="center" alignItems="center" align="center">
                        <TextField 
                            id="appNo"
                            name="appNo"
                            label="Application Number"
                            onBlur={this.changeHandler}
                            fullWidth
                            variant="outlined"
                            required
                            className={classes.txtFields}
                        />
                        <TextField 
                            id="passCode"
                            name="passCode"
                            label="Pass Code"
                            onBlur={this.changeHandler}
                            fullWidth
                            variant="outlined"
                            required   
                            className={classes.txtFields}
                        />

                        <Button 
                            variant="contained" align="center" color="primary" 
                            onClick={this.checkApplicationResult}
                            style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} >
                                Check Result
                        </Button>
                        <div> 
                            <Button style={{fontSize:'0.8rem',textTransform:'none',textDecoration:'underline'}} align="center" color="primary" onClick={this.handleForgotAccountBtnClicked}>
                                    Forgot Account
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            
                {/* Results Component */}
                <ApplicationResult
                    resultsCheckingPeriod={this.state.resultsCheckingPeriod}
                    applicantTypeID={this.state.applicantTypeID}
                    applicantNo={this.state.applicantNo}
                    applicantStatus={this.state.applicantStatus}
                    applicantName={this.state.applicantName}
                    applicantProgram={this.state.applicantProgram}
                    applicantMajor={this.state.applicantMajor}
                    applicantCollege={this.state.applicantCollege}
                    applicantCampus={this.state.applicantCampus}
                    applicantIsEnlisted={this.state.applicantIsEnlisted}
                    applicantProfilePhoto={this.state.applicantProfilePhoto}
                />
               
                {/* (For Qualified Applicants) Confirm Button for Enlistment */}
                {this.state.applicantStatus === 'Qualified' && !this.state.applicantIsEnlisted ? 
                    <Grid item xs={12} spacing={2} sm={6} style={{margin:"0 auto"}} alignContent="center" alignItems="center" align="center">
                        <Button 
                            variant="contained" align="center" color="primary" 
                            onClick={this.handleConfirmBtnClick}
                            style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} >
                                Confirm
                        </Button> 
                    </Grid>
                : null }

                {/* (For Waitlisted Applicants) List of Programs w/ Available Slots  */}
                {this.state.loadingProgramWithAvailableSlots ?     
                    <div>Loading Programs With Available Slots...</div>
                : null }
                
                {this.state.applicantStatus === 'Waitlisted' && this.state.displayProgramWithAvailableSlots ?
                    <ListOfProgramsWithSlots
                        programList={this.state.listOfProgramsWithSlots}
                        handleSelectAvailProgramClick={this.handleSelectAvailProgramClick}
                        appNo={this.state.appNo}
                        passCode={this.state.passCode}
                    />
                : null
                }
                
                {/* Forgot Account Component */}
                {this.state.hasClickedForgotAccount ?
                    <ForgotAccountDialog
                        forgotAccountHandler={this.getForgottenAccount}
                        handleForgotAccountCancelBtnClicked={this.handleForgotAccountCancelBtnClicked}
                    />
                : null }

                {/* (For Qualified Applicants Enlistment) Confirmation Dialog */}
                <Dialog fullScreen open={this.state.hasClickedConfirmBtn} >
                    <DialogContent>
                        <DialogContentText >
                            <Typography paragraph variant="body1">
                                You are about to confirm enlistment to the academic program: &nbsp;&nbsp;
                                <div style={{fontWeight:'bold'}}>
                                    {this.state.applicantProgram ? this.state.applicantProgram : null}
                                    {this.state.applicantMajor ? ` major in ${this.state.applicantMajor}` : null}
                                </div>
                                <br/>
                                Please hit the 'Confirm' button to finalize your enlistment to the program.
                                <br/> 
                                <span style={{color:'maroon',fontSize:'0.9rem',fontStyle:'italic'}}>(Warning! This cannot be undone.)</span> 
                            </Typography>
                        </DialogContentText>
                     
                        <Button autoFocus onClick={this.handleCancelConfirmDialog} color="primary">
                            Cancel
                        </Button>
                        {/* <Button onClick={handleConfirmBtnClick} disabled={!passCodeIsValid} color="primary"> */}
                        <Button onClick={this.handleConfirmBtnDialogClick} color="primary">
                            Confirm
                        </Button>
                    </DialogContent>
                </Dialog>
                

                <Backdrop open={this.state.submitting} style={{zIndex:"9000"}}>
                    <CircularProgress style={{zIndex:"9000"}}/> 
                </Backdrop > 

            </Container>
        )

    }
}

export default withStyles(styles)(ResultChecker);