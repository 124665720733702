
const init = {
    
    isFilipino: null, //yes:1,no:0
    courseCategoryID: null, //undergrad:1,grad:2,eteeap:3,
    applicantEntryTypeID: null, //1stYear:1,transferee:2
    hasAgreedToDataPrivacyStatement: false,
    isK12Graduate: null, //yes:1,no:0
    isVocationalCourseGraduate: null, //yes:1,no:0
    applicationStatus: null,
    foreignLastName: null,
    foreignFirstName: null,
    foreignMiddleName: null,
    foreignSuffix: null,
    foreignOtherName: null,
    foreignPassportNumber: null,
    foreignPassportPlaceIssued: null,
    foreignPassportDateIssued: null,
    foreignPassportExpiryDate: null,
    passportPhoto: null
}


const applicant = (state = init, action) => {
    switch(action.type){
        case "IS_FILIPINO_SET":
            return {
                ...state,
                isFilipino: action.payload.isFilipino
            }
        case "COURSE_CATEGORY_APPPLIED_SET":
            return {
                ...state,
                courseCategoryID: action.payload.courseCategoryID
            }
        case "APPLICANT_ENTRY_TYPE_SET":
            return {
                ...state,
                applicantEntryTypeID: action.payload.applicantEntryTypeID
            }
        case "DATA_PRIVACY_CONSENT_SET":
            return {
                ...state,
                hasAgreedToDataPrivacyStatement: action.payload.hasAgreedToDataPrivacyStatement
            }
        case "K12_GRADUATE_SET":
            return {
                ...state,
                isK12Graduate: action.payload.isK12Graduate
            }
        case "VOCATIONAL_COURSE_GRADUATE_SET":
            return {
                ...state,
                isVocationalCourseGraduate: action.payload.isVocationalCourseGraduate
            }
        case "APPLICATION_STATUS_SET":
            return {
                ...state,
                applicationStatus: {...action.payload}
            }
        case "FOREIGN_FIRST_NAME_SET":
            return {
                ...state,
                foreignFirstName: action.payload.foreignFirstName
            }
        case "FOREIGN_LAST_NAME_SET":
            return {
                ...state,
                foreignLastName: action.payload.foreignLastName
            }
        case "FOREIGN_MIDDLE_NAME_SET":
            return {
                ...state,
                foreignMiddleName: action.payload.foreignMiddleName
            }
        case "FOREIGN_SUFFIX_SET":
            return {
                ...state,
                foreignSuffix: action.payload.foreignSuffix
            }
        case "FOREIGN_OTHER_NAME_SET":
            return {
                ...state,
                foreignOtherName: action.payload.foreignOtherName
            }
        case "FOREIGN_PASSPORT_NUMBER_SET":
            return {
                ...state,
                foreignPassportNumber: action.payload.foreignPassportNumber
            }
        case "FOREIGN_PASSPORT_PLACEISSUED_SET":
            return {
                ...state,
                foreignPassportPlaceIssued: action.payload.foreignPassportPlaceIssued
            }
        case "FOREIGN_PASSPORT_DATEISSUED_SET":
            return {
                ...state,
                foreignPassportDateIssued: action.payload.foreignPassportDateIssued
            }
        case "FOREIGN_PASSPORT_EXPIRYDATE_SET":
            return {
                ...state,
                foreignPassportExpiryDate: action.payload.foreignPassportExpiryDate
            }
        case "PASSPORT_PHOTO_SET":
            return {
                ...state,
                passportPhoto: action.payload
            }
        default:
            return state;
    }
}

export default applicant;