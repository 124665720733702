import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';

import axios from 'axios';
import { API_URL } from './config';
import { CURR_TERMID } from './config';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar, GridToolbarExport,GridToolbarContainer } from '@material-ui/data-grid';

import {
    Container,
    Typography,
    Grid,
    TextField,
    Button,
    Paper,
    TableContainer,
    TableRow,
    Table,
    TableHead,
    TableCell,
    TableBody,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import RankingDetails from './RankingDetails';
import CustomToolbar from './CustomToolbar';



let socket = null; 
let AUTH_HEADER = null;

let columnsForEnlisted = [
    { field: 'rank', headerName: 'Rank', width: 110, headerAlign: 'center' },
    { 
        field: 'status', headerName: 'Status', width: 120,
        renderCell: (params) => {
            const status = params.row.isPreviouslyWaitlisted ? <React.Fragment><span>{params.value}</span><br/><span>(From Wait List)</span></React.Fragment>: <React.Fragment><span>{params.value}</span></React.Fragment>;
            return(
                <React.Fragment>
                    {params.value}
                </React.Fragment>
            )
        }

    },
    {field: 'coursePreferenceLvl', headerName: 'Course Priority', width: 180},
    { field: 'appName', headerName: 'Applicant Name', width: 350 },
    { field: 'finalRating', headerName: 'Final Rating', width: 160 },
    { field: 'email', headerName: 'Email', width: 230 },
    { field: 'mobileNo', headerName: 'Mobile No.', width: 160 },
    // { field: 'finalGradesAvg', headerName: 'Final Grades Avg', width: 180 },
    { field: 'finalGradesAvgW', headerName: 'Final Grades Weight', width: 230 },
    // { field: 'gpaGradesAvg', headerName: 'GPA Avg', width: 130 },
    { field: 'gpaGradesAvgW', headerName: 'GPA Weight', width: 180 },
    // { field: 'motherIncomePts', headerName: 'Mother Income Pts', width: 130 },
    // { field: 'fatherIncomePts', headerName: 'Father Income Pts', width: 130 },
    // { field: 'guardianIncomePts', headerName: 'Guardian Income Pts', width: 130 },
    // { field: 'incomeSourceAvg', headerName: 'Income Source Avg', width: 130 },
    { field: 'incomeSourceAvgW', headerName: 'Income Weight', width: 180 },
    {field: 'enlistmentMode', headerName: 'Enlistment Mode', width: 190}

  ];

let columnsForCandidates = [
    { field: 'rank', headerName: 'Rank', width: 110, headerAlign: 'center' },
    { field: 'status', headerName: 'Status', width: 120 },
    {field: 'coursePreferenceLvl', headerName: 'Course Priority', width: 180},
    { field: 'appName', headerName: 'Applicant Name', width: 350 },
    { field: 'finalRating', headerName: 'Final Rating', width: 180 },
    { field: 'finalGradesAvgW', headerName: 'Final Grades Weight', width: 230 },
    { field: 'gpaGradesAvgW', headerName: 'GPA Weight', width: 180 },
    { field: 'incomeSourceAvgW', headerName: 'Income Weight', width: 180 }
    
  ];

let columnsForMasterlist = [
    { field: 'rank', headerName: 'Rank', width: 120, headerAlign: 'center' },
    // { field: 'status', headerName: 'Status', width: 120 },
    { field: 'appName', headerName: 'Applicant Name', width: 350 },
    { field: 'finalRating', headerName: 'Final Rating', width: 180 },
    { field: 'finalGradesAvgW', headerName: 'Final Grades Weight', width: 230 },
    { field: 'gpaGradesAvgW', headerName: 'GPA Weight', width: 180 },
    { field: 'incomeSourceAvgW', headerName: 'Income Weight', width: 180 }
    
  ];
  
const tableColumns = [
    {field: "subject", headerName: "Subject", width: 100}, 
    {field: "grade9", headerName: "Grade 9", width: 100}, 
    {field: "grade10", headerName: "Grade 10", width: 100}, 
    {field: "grade11", headerName: "Grade 11", width: 100}, 
    {field: "average", headerName: "Average", width: 100}, 
];

const tableRows = [
    {id: 1, subject: "English", grade9: 99.9, grade10: 99.9, grade11: 99.9, average: 99.9}
];

const styles = theme => ({
    root: {
        '& .second-course-option': {
            backgroundColor: '#ffd585'
        },
        '& .third-course-option': {
            backgroundColor: '#ffc6ab'
        }
    },
    resultsSnapshotTdLabel: {
        width: '70px',
        textAlign: 'center',
        padding: '0px'
    },
    resultsSnapshotTdValue: {
        width: '70px',
        fontSize: '1.15rem',
        textAlign: 'center',
        padding: '0px'
    }
    
});

class Rankings extends Component{

    state = {
        tableColumns: columnsForEnlisted,
        tableRows: [],

        tableRowsFinalGrades: [],
        finalGradesAvg: 0,
        finalGradesWeight: 0,

        tableRowsGPA: [],
        gpaGradesAvg: 0,
        gpaGradesWeight: 0,

        tableRowsIncome: [],
        incomeSourceAvg: 0,
        incomeSourceWeight: 0,
        applicantInfoForRanking: null,
        applicantProfilePhoto: null,
        
        selectedCampus: null,
        selectedCollege: null,
        selectedProgram: null,
        selectedMajor: null,
        selectedListView: 'listEnlisted',

        rankingFilters: {},
        campusOptions: [],
        collegeOptions: [],
        programOptions: [],
        majorOptions: [],
        rankingScope: '1',
        rankingScopeValid: true,
        rankingScopeInvalid: false,
        rankingScopeAll: false,
        selectedAppNo: '',

        tableRankingDetailsColumns: tableColumns,
        tableRankingDetailsRows: tableRows,
        rankedApplicantsStats: null
    }

    changeHandler = (prop, value) => {
        console.log(`prop: ${prop}, value: ${value}`);
        if(prop === 'selectedCampus'){
            this.setState({
                selectedCollege: null,
                selectedProgram: null,
                selectedMajor: null
            });
        }else if(prop === 'selectedCollege'){
            this.setState({
                selectedProgram: null,
                selectedMajor: null
            });
        }else if(prop === 'selectedProgram'){
            this.setState({
                selectedMajor: null
            });
        }

        this.setState({
            [prop]: value
        }, () => {
            this.getRankingResults();
        });
    }

    getEnlistedApplicants = () => {
        const campusID = this.state.selectedCampus ? this.state.selectedCampus.CampusID : 0;
        const collegeID = this.state.selectedCollege ? this.state.selectedCampus.CollegeID : 0;
        const programID = this.state.selectedProgram ? this.state.selectedProgram.ProgID : 0;
        const majorID = this.state.selectedMajor ? this.state.selectedMajor.MajorID : 0;

        axios.get(`${API_URL}/admission/getEnlistedApplicants
            /${programID}
            /${majorID}
        `)
        .then( (response) => {
            console.log(`${JSON.stringify(response.data)}`);
            if(response.data){
                
            }
        })

    }

    getRankingResults = () => {
        const campusID = this.state.selectedCampus ? this.state.selectedCampus.CampusID : 0;
        const collegeID = this.state.selectedCollege ? this.state.selectedCampus.CollegeID : 0;
        const programID = this.state.selectedProgram ? this.state.selectedProgram.ProgID : 0;
        const majorID = this.state.selectedMajor ? this.state.selectedMajor.MajorID : 0;
        const rankingScope = this.state.rankingScope;
        const listView = this.state.selectedListView;

        // let endpoint = `${API_URL}/admission/${listView === 'listCandidates' ? 'getRankingResultsOfficial' : 'getApplicantMasterlist'}`;
        let endpoint = '';
        let tableColumns = [];

        if(listView === 'listCandidates'){
            endpoint = 'getRankingResultsOfficial';
            tableColumns = columnsForCandidates;
        }else if(listView === 'listEnlisted'){
            endpoint = 'getEnlistedApplicants';
            tableColumns = columnsForEnlisted;
        }else if(listView === 'listApplicants'){
            endpoint = 'getApplicantMasterlist';
            tableColumns = columnsForMasterlist;
        }
   
        if(campusID !== 0 && programID !== 0){

            axios.get(`${API_URL}/admission/${endpoint}/${campusID}
                /${collegeID}
                /${programID}
                /${majorID}
                /${rankingScope}`,
                this.props.authHeader
            )
            .then( (response) => {
                console.log(`responseData: ${JSON.stringify(response.data)}`);
                if(response.data !== null){

                    let rankedApplicantsStats = {
                        total: response.data.length,
                        qualified: response.data.filter( (key) => key.status === 'Qualified').length,
                        enlistedQualified: response.data.filter( (key) => key.statusForEnlisted === 'EnlistedQualified').length,
                        waitlisted: response.data.filter( (key) => key.status === 'Waitlisted').length,
                        enlistedWaitlisted: response.data.filter( (key) => key.statusForEnlisted === 'EnlistedWaitlisted').length,
                        firstChoiceCount: response.data.filter( (key) => key.coursePreferenceLvl === 1).length, 
                        secondChoiceCount: response.data.filter( (key) => key.coursePreferenceLvl === 2 ).length, 
                        thirdChoiceCount: response.data.filter( (key) => key.coursePreferenceLvl === 3 ).length,
                        stem: response.data.filter( (key) => key.Strand_ID === 20 ).length,
                        humss: response.data.filter( (key) => key.Strand_ID === 19 ).length,
                        gas: response.data.filter( (key) => key.Strand_ID === 21 ).length,
                        abm: response.data.filter( (key) => key.Strand_ID === 18 ).length,
                        tvl: response.data.filter( (key) => key.Track_ID === 2 ).length,
                        sports: response.data.filter( (key) => key.Track_ID === 3 ).length,
                        arts: response.data.filter( (key) => key.Track_ID === 4 ).length,
                         
                    };

                    let parsedRows = response.data.map( (key) => {
                        return {
                            rank: key.rank,
                            status: key.status,
                            id: key.AppNo,
                            appName: key.AppName,
                            finalRating: key.finalRating,
                            email: key.email,
                            mobileNo: key.mobileNo,
                            finalGradesAvg: key.finalGradesAvg,
                            finalGradesAvgW: key.finalGradesAvgW,
                            gpaGradesAvg: key.gpaGradesAvg,
                            gpaGradesAvgW: key.gpaGradesAvgW,
                            // motherIncomePts: key.motherIncomePoints,
                            // fatherIncomePts: key.fatherIncomePoints,
                            // guardianIncomePts: key.guardianIncomePoints,
                            incomeSourceAvg: key.incomeSourceAvg,
                            incomeSourceAvgW: key.incomeSourceAvgW,
                            coursePreferenceLvl: key.coursePreferenceLvl,
                            isPreviouslyWaitlisted: key.isPreviouslyWaitlisted,
                            enlistmentMode: key.isPreviouslyWaitlisted ? 'Via Waitlist' : 'Via Top 45'
                        }
                    });
                    
                    this.setState({
                        tableRows: parsedRows,
                        tableColumns: tableColumns,
                        rankedApplicantsStats: rankedApplicantsStats
                    });

                }else{
                    this.setState({
                        tableRows: []
                    });
                }
            });

        }

    }

    getRankingControlFilters = () => {
        console.log(`authHeaderInRankingControlFilters:${JSON.stringify(this.props.authHeader)}`);
        axios.get(`${API_URL}/admission/getRankingControlFilters`)
            .then( (response) => {
                if(response.data){
                    console.log(`rankingFilters: ${response.data}`);

                    const userProgramAccess = this.props.userProgramAccess;
                    const userType = this.props.userType;

                    const campusOptionsFiltered = response.data.campuses.filter( (campus) => {
                        for(const userAccess of userProgramAccess){
                            if(campus.CampusID === userAccess.campus_id){
                                return campus;
                            }
                        }
                    })
                    const collegeOptionsFiltered = response.data.colleges.filter( (college) => {
                        for(const userAccess of userProgramAccess){
                            if(college.CollegeID === userAccess.college_id){
                                return college;
                            }
                        }
                    })
                    const programOptionsFiltered = response.data.programs.filter( (program) => {
                        for(const userAccess of userProgramAccess){
                            if(program.ProgID === userAccess.prog_id){
                                return program;
                            }
                        }
                    })
                    const majorOptionsFiltered = response.data.majors.filter( (major) => {
                        for(const userAccess of userProgramAccess){
                            if(major.MajorID === userAccess.major_id){
                                return major;
                            }
                        }
                    })

                    console.log(`collegeOptionsFiltered: ${JSON.stringify(collegeOptionsFiltered)}`);
                    console.log(`programOptionsFiltered: ${JSON.stringify(programOptionsFiltered)}`);
                    console.log(`majorOptionsFiltered: ${JSON.stringify(majorOptionsFiltered)}`);

                    this.setState({
                        rankingFilters: {...response.data},
                        // collegeOptions: [...response.data.colleges],
                        campusOptions: userType === 'dean' ? [...campusOptionsFiltered]  : [...response.data.campuses], 
                        collegeOptions: userType === 'dean' ? [...collegeOptionsFiltered]  : [...response.data.colleges] ,
                        programOptions: [...response.data.programs],
                        // programOptions: [...programOptionsFiltered],
                        majorOptions: [...response.data.majors],
                        // majorOptions: [...majorOptionsFiltered],
                    }, () => {
                        // console.log(`collegeOptions: ${JSON.stringify(this.state.collegeOptions)}`);
                    });
                }
            })
    }

    getRankingDetails = () => {
        axios.get(`${API_URL}/admission/getRankingDetails/${this.state.selectedAppNo}`,
            this.props.authHeader
        )
            .then( (response) => {
                if(response.data){
                    this.setState({
                        tableRowsFinalGrades: [...response.data.finalGrades],
                        finalGradesAvg: response.data.finalGradesAvg,
                        finalGradesWeight: response.data.finalGradesWeight,
                        tableRowsGPA: response.data.gpaGrades,
                        gpaGradesAvg: response.data.gpaGradesAvg,
                        gpaGradesWeight: response.data.gpaGradesWeight,
                        tableRowsIncome: response.data.incomeSource,
                        incomeSourceAvg: response.data.incomeSourceAvg,
                        incomeSourceWeight: response.data.incomeSourceWeight,
                        applicantInfoForRanking: {...response.data.applicantInfo}
                    }, () => {
                        console.log(`applicantInfo: ${JSON.stringify(this.state.applicantInfoForRanking)}`);
                    })
                }
            });
    }

    getProfilePhoto = () => {
        axios.get(`${API_URL}/admission/getUploadedFile/${this.state.selectedAppNo}/6`,
            this.props.authHeader
        )
            .then( (response) => {
                if(response.data){
                    this.setState({
                        applicantProfilePhoto: response.data
                    });
                }
            });
    }

    handleApplicantSelection = (appNo) => {
        this.setState({
            selectedAppNo: appNo
        }, () => {
            this.getRankingDetails();
            this.getProfilePhoto();
        });
    }
    
    handleListViewSelection = (e) => {
        const listView = e.currentTarget.id;
        this.setState({
            selectedListView: listView
        }, () => {
            this.getRankingResults();
        });
    }

    componentDidMount(){
        this.getRankingControlFilters();
        this.getRankingResults();
    }

    render(){

        const { classes } = this.props;

        return(
            <Container maxWidth="xl">
                <Grid item container xs={12} sm={12}>

                    {/* Ranking Results Container */}
                    <Grid item container xs={12} sm={this.state.selectedAppNo ? 8 : 12} style={{paddingRight:"10px"}}>
                        
                        <Typography variant="h6">Ranking Results</Typography>
                        <Grid item container xs={12} sm={12}>
                            {/* Program Filter */}
                            <Grid item xs={12} sm={6}>
                                <Autocomplete 
                                    id="selectedCampus"
                                    name="selectedCampus"
                                    value={this.state.selectedCampus}
                                    options={this.state.campusOptions}
                                    getOptionLabel={(option) => option.CampusName}
                                    fullWidth
                                    onChange={(e, selectedOption) => { 
                                        this.changeHandler("selectedCampus", selectedOption ? selectedOption : null)
                                    }}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            label="Campus"
                                        />
                                    }
                                />
                                <Autocomplete 
                                    id="selectedCollege"
                                    name="selectedCollege"
                                    value={this.state.selectedCollege}
                                    options={this.state.collegeOptions.filter( (college) => {
                                        return (this.state.selectedCampus ? college.CampusID === this.state.selectedCampus.CampusID : null)
                                    }) }
                                    getOptionLabel={(option) => option.CollegeName}
                                    fullWidth
                                    onChange={(e,selectedOption) => { this.changeHandler("selectedCollege", selectedOption ? selectedOption: null)}}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            label="College"
                                        />
                                    }
                                />
                                <Autocomplete 
                                    id="selectedProgram"
                                    name="selectedProgram"
                                    value={this.state.selectedProgram}
                                    options={this.state.programOptions.filter( (program) => {
                                        return (this.state.selectedCollege ? program.CollegeID === this.state.selectedCollege.CollegeID : null) 
                                    })}
                                    getOptionLabel={(option) => option.ProgName}
                                    fullWidth
                                    onChange={(e,selectedOption) => { this.changeHandler("selectedProgram", selectedOption ? selectedOption: null)}}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            label="Program"
                                        />
                                    }
                                />
                                <Autocomplete 
                                    id="selectedMajor"
                                    name="selectedMajor"
                                    value={this.state.selectedMajor}
                                    options={this.state.majorOptions.filter( (major) => {
                                        return (this.state.selectedProgram ? major.ProgID === this.state.selectedProgram.ProgID : null)
                                    })}
                                    getOptionLabel={(option) => option.Major}
                                    fullWidth
                                    onChange={(e,selectedOption) => { this.changeHandler("selectedMajor", selectedOption ? selectedOption : null)}}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            label="Major"
                                        />
                                    }
                                />
                                
                                {/* <FormControl component="fieldset">
                                    <RadioGroup
                                        row aria-label="rankingScope"
                                        id="rankingScope"
                                        name="rankingScope"
                                        value={this.state.rankingScope}
                                        onChange={(e) => {this.changeHandler("rankingScope",e.target.value)}}
                                    >
                                        <FormControlLabel value='1' control={<Radio />} label="Valid Only" />
                                        <FormControlLabel value='2' control={<Radio />} label="Invalid Only" />
                                        <FormControlLabel value='3' control={<Radio />} label="All" />
                                    </RadioGroup>
                                </FormControl> */}

                            </Grid>


                            {/* Ranking Stats */}
                            <Grid item container xs={12} sm={6}>
                                {/* <Grid item xs={12} sm={12}>
                                    <Typography style={{color:'#8d8d8d',fontSize:'1rem'}}>Results Snapshot</Typography>
                                </Grid>
                                 */}
                                {/* Ranked Applicants */}
                                <Grid item container xs={12} sm={12}>
                                    <Typography style={{width:'100%',textAlign:'center'}}>Distribution of Ranked Applicants</Typography>
                                    <Grid item xs={12} sm={12}>
                                        
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            Total:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>
                                                            {this.state.rankedApplicantsStats  ? this.state.rankedApplicantsStats.total : ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                             {this.state.selectedListView === 'listEnlisted' ? 'From Top 45:' : '' } 
                                                             {this.state.selectedListView === 'listCandidates' ? 'Qualified:' : '' } 
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>
                                                            {this.state.rankedApplicantsStats && this.state.selectedListView === 'listCandidates' ? this.state.rankedApplicantsStats.qualified : ''}
                                                            {this.state.rankedApplicantsStats && this.state.selectedListView === 'listEnlisted' ? this.state.rankedApplicantsStats.enlistedQualified : ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            {this.state.selectedListView === 'listEnlisted' ? 'From Waitlisted:' : '' } 
                                                            {this.state.selectedListView === 'listCandidates' ? 'Waitlisted:' : '' } 
                                                            
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>
                                                            {this.state.rankedApplicantsStats && this.state.selectedListView === 'listCandidates' ? this.state.rankedApplicantsStats.waitlisted: ''}
                                                            {this.state.rankedApplicantsStats && this.state.selectedListView === 'listEnlisted' ? this.state.rankedApplicantsStats.enlistedWaitlisted: ''}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="caption" style={{backgroundColor:'gainsboro',fontSize: '0.9rem'}}>By Course Preference Level</Typography>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            1st Choice:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.firstChoiceCount : ''}</TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            2nd Choice:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.secondChoiceCount : ''}</TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            3rd Choice:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.thirdChoiceCount : ''}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                    
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="caption" style={{backgroundColor:'gainsboro',fontSize: '0.9rem'}}>By Track/Strand</Typography>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            STEM:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.stem : ''}</TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            HUMSS:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.humss : ''}</TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            GAS:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.gas : ''}</TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            ABM:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.abm : ''}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        {/* <Typography variant="caption" style={{backgroundColor:'gainsboro',fontSize: '0.9rem'}}>By Track/Strand</Typography> */}
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow key={1}>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            TVL:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.tvl : ''}</TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            SPORTS:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.sports : ''}</TableCell>
                                                        <TableCell component="th" scope="row" className={classes.resultsSnapshotTdLabel}>
                                                            ARTS:
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.resultsSnapshotTdValue}>{this.state.rankedApplicantsStats ? this.state.rankedApplicantsStats.arts : ''}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                </Grid>

                            </Grid> 

                        </Grid>

                        <Grid item xs={12} sm={6} style={{margin:'10px auto',textAlign:'center'}} alignContent="center" alignItems="center">
                            <Button id="listEnlisted" onClick={this.handleListViewSelection} variant={this.state.selectedListView === 'listEnlisted' ? 'contained' : ''} color={this.state.selectedListView === 'listEnlisted' ? 'primary' : ''}>Enlisted Applicants</Button> | 
                            <Button id="listCandidates" onClick={this.handleListViewSelection} variant={this.state.selectedListView === 'listCandidates' ? 'contained' : ''} color={this.state.selectedListView === 'listCandidates' ? 'primary' : ''}>Candidates for Admission</Button> 
                            {this.props.userType !== 'dean' ? (
                                <React.Fragment>
                                    <span>|</span> 
                                    <Button id="listApplicants" onClick={this.handleListViewSelection} variant={this.state.selectedListView === 'listApplicants' ? 'contained' : ''} color={this.state.selectedListView === 'listApplicants' ? 'primary' : ''}>Applicants Master List</Button>
                                </React.Fragment>
                            ) : null}
                        
                        </Grid>

                        {/* <CustomToolbar /> */}
                        
                        {/* Ranking Results Table */}
                        <div style={{ height: 800, width: '100%' }} className={classes.root}>
                            <DataGrid 
                                rows={this.state.tableRows} 
                                columns={this.state.tableColumns} 
                                components={{
                                    Toolbar: GridToolbar,
                                  }}
                                pageSize={50}
                                onRowClick={(param, event) => {
                                    this.handleApplicantSelection(param.id);
                                }}
                                
                                getRowClassName={(params) => {
                                    if(params.row.rank > 45){
                                        return `second-course-option`;
                                    }
                                    // else if(params.row.coursePreferenceLvl === 3){
                                    //     return `third-course-option`;
                                    // }
                                    return '';
                                    // console.log(`paramsID: ${params.id}, appName: ${params.getValue(params.id, 'appName') } `);
                                }}    
                            />
                        </div>

                    </Grid>

                    {/* Ranking Details Container */}
                    {this.state.selectedAppNo ? 
                        <Grid item container xs={12} sm={4}>
                            <RankingDetails 
                                tableRowsFinalGrades={this.state.tableRowsFinalGrades}
                                finalGradesAvg={this.state.finalGradesAvg}
                                finalGradesWeight={this.state.finalGradesWeight}
                                tableRowsGPA={this.state.tableRowsGPA}
                                gpaGradesAvg={this.state.gpaGradesAvg}
                                gpaGradesWeight={this.state.gpaGradesWeight}
                                tableRowsIncome={this.state.tableRowsIncome}
                                incomeSourceAvg={this.state.incomeSourceAvg}
                                incomeSourceWeight={this.state.incomeSourceWeight}
                                applicantInfo={this.state.applicantInfoForRanking}
                                applicantProfilePhoto={this.state.applicantProfilePhoto}
                                userType={this.props.userType}
                                // tableColumns={this.state.tableRankingDetailsColumns}
                            />
                        </Grid>
                     : null} 


                </Grid>
            </Container>
            
        )
    }
}
  
export default withStyles(styles)(Rankings);