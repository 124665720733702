
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import {
    Dialog,
    DialogContent,
    Button,
    Divider,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    DialogActions
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

// actions
// import { setIsFilipino } from "../../redux/actions/applicant"; 
// import allActions from '../../redux/actions';
import { setIsFilipinoAction } from "../../redux/actions/applicant";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Undergraduate = () => {

    let history = useHistory();

    const [isFilipino, setIsFilipino] = useState(null);
    const [applicationType, setApplicationType] = useState();

    const applicant  = useSelector(state => state.applicant);

    const onConfirmFilipino = (data) => {
        // setIsFilipino(data);
        setIsFilipinoAction(data);
    }

    const onSelectEntryType = (data) => {

    }

    useEffect(() => {
        if(applicant.isFilipino)
            alert(`Hello, ${applicant.isFilipino}`);
        
    }, [applicant.isFilipino])

    return (
        <div>
            <Dialog
                open={true}
                TransitionComponent={Transition}
            >
        
                {/* <AppBar sx={{ position: 'relative' }} >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => alert('Close')}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Confirm</Typography>
                        <Button autoFocus color="inherit" onClick={() => alert('Saved')}>
                            NEXT
                        </Button>
                    </Toolbar>
                </AppBar> */}
                <DialogContent>
                    {/* <Typography variant="h1">Hello, {applicant.isFilipino}</Typography> */}
                    <Grid container spacing={2}  >
                        <Grid item xs={12}>
                            <Typography>Are you a Filipino citizen?</Typography>
                            <Button 
                                style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onConfirmFilipino('Yes')} >Yes</Button> 
                        <Button 
                                style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onConfirmFilipino('No')} >No</Button> 
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Are you applying as an incoming First Year student or a Transferee?</Typography>
                            <Button 
                                style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => alert('Yes') } >First Year</Button> 
                        <Button 
                                style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => alert('No') } >Transferee</Button> 
                        </Grid>
                    </Grid> 
                </DialogContent>

                <DialogActions>
                    <Button 
                        style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                        onClick={() => alert('Yes') } >Cancel</Button> 
                    <Button 
                        style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                        onClick={() => {history.push('/regForm')} }>Proceed</Button> 
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default Undergraduate;