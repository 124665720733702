
import axios from 'axios';

import {dispatch} from './index';
import {CONFIG} from '../../AppConfig';

const URL = CONFIG.URL_API;


export const resetTestSchedule = (prop) => {
    switch(prop) {
        case 'testCenter':
            dispatch("TESTING_DATES_SET", null);
            dispatch("TESTING_TIMES_SET", null);
            dispatch("TESTING_ROOMS_SET", null);
            return;
        default:
            return true
    }
}


export const getTestingCenters = (data) => {
    let payload = {};
    axios.get(
        `${URL}/admission/getTestingCenters`,
        data
    ).then ( (response) => {
        console.log(`TESTING_CENTERS: ${JSON.stringify(response.data)}`);
        payload = response.data;
        dispatch("TESTING_CENTERS_SET", payload);
    })

}

export const getTestingDates = (data) => {
    let payload = {};
    const { termID,testCenterID } = data;

    axios.get(
        `${URL}/admission/getAvailableExamDates/${termID}/${testCenterID}`
    ).then( (response) => {
        console.log(`TESTING_DATES: ${JSON.stringify(response.data)}`);
        payload = response.data;
        dispatch("TESTING_DATES_SET", payload);
    })

}

export const getTestingTimes = (data) => {
    let payload = {};
    const { termID,testCenterID,testDateID } = data;

    axios.get(
        `${URL}/admission/getAvailableExamTimes/${termID}/${testCenterID}/${testDateID}`
    ).then( (response) => {
        console.log(`TESTING_TIMES: ${JSON.stringify(response.data)}`);
        payload = response.data;
        dispatch("TESTING_TIMES_SET", payload);
    })

}

export const getTestingRooms = (data) => {
    let payload = {};
    const { termID,testCenterID,testDateID,testTimeID } = data;

    axios.get(
        `${URL}/admission/getAvailableExamRooms/${termID}/${testCenterID}/${testDateID}/${testTimeID}`
    ).then( (response) => {
        console.log(`TESTING_ROOMS: ${JSON.stringify(response.data)}`);
        payload = response.data;
        dispatch("TESTING_ROOMS_SET", payload);
    })

}

export const getActiveTermID = () => {
    let payload = {};
    axios.get(
        `${URL}/admission/getActiveTermID/`
    ).then( (response) => {
        // console.log(`ACTIVE_TERM_ID: ${JSON.stringify(response.data)}`);
        payload = response.data.activeTermID;
        
        dispatch("ACTIVE_TERM_ID_SET", payload);
    })
}

export const saveApplicantTestSchedule = (data, onSuccess, onFailure) => {
    axios.post(
        `${URL}/admission/saveApplicantTestSchedule`,
        data
    ).then( (response) => {
        console.log(`SAVE APPLICANT TEST SCHEDULE: ${JSON.stringify(response.data)}`);
        if(response.data.status){
            onSuccess({...response.data});
        }else{
            onFailure({...response.data});
        }
        
    }).catch( (err) => {
        console.log(`SAVE APPLICANT TEST SCHEDULE: ${err}`)
        onFailure();
    })
}