import React, {Component} from 'react';

import { 
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    IconButton,
    Paper
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

// react pdf
import {DownloadNOAP, ViewNOAP} from "../reports/NOAP";

const useStyles = makeStyles ({
    formHeader: {
        fontFamily: `"Quicksand", sans-serif`,
        fontWeight: 500,
        color:  `#fff`,  //`#d58a13`, //"#707070",
        marginTop: `15px`,
        marginBottom: `20px`,
        width: `100%`,
        background: `rgb(215, 93, 95) none repeat scroll 0% 0%`
    },
    resultMsg: {
        fontFamily: `"Quicksand", "Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: 1.5,
        color: 'black'
    },
    resultRemarks: {
        backgroundColor: `#32bd32`,
        color: `#fff`,
        width: `50%`,
        textAlign: `center`,
        margin: `10px auto`
    },
    resultRemarksWaitlist: {
        backgroundColor: `#e3b93d`,
        color: `#fff`,
        width: `50%`,
        textAlign: `center`,
        margin: `10px auto`
    },
    resultRemarksWaivedSlot: {
        backgroundColor: `maroon`,
        color: `#fff`,
        width: `50%`,
        textAlign: `center`,
        margin: `10px auto`
    },
    inlineTextEmphasize: {
        fontWeight: `bold`
    },
    inlineTextEmphasizeWarning: {
        fontWeight: `bold`,
        color: `maroon`
    }
});

const applicationResult = (props) => {
    const classes = useStyles();

    const NOAP_DATA = {
        date: new Date().toLocaleString('en-US', {
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'long', // numeric, 2-digit, long, short, narrow
         }),
         name: props.applicantName,
         college: props.applicantCollege,
         campus: props.applicantCampus,
         program: `${props.applicantProgram} ${props.applicantMajor ? `major in ${props.applicantMajor}` : ''}`,
         yearLevel: "1st year",
         semester: "1st Semester",
         academicYear: "2021-2022",
    }

    const resultSignatory = (
        <Typography variant="body" className={classes.resultMsg}>
            <span style={{fontWeight:'bold'}}>FEDELIS C. BONOCAN, PhD</span>
            <br/>
            Deputy Director
            <br/>
            Admissions Unit
            <br/>
            Office of the University Registrar
        </Typography>
    );

    const resultMsgQualified = (
        <Typography variant="body" className={classes.resultMsg}>
            We are pleased to inform you that you are <span className={classes.inlineTextEmphasize}>ONE of the qualifiers</span> in your preferred course as indicated above 
            for the Academic Year 2021-2022 pursuant to the results of evaluation of your application. 
            Hence, we request you to <span className={classes.inlineTextEmphasize}>confirm your slot by clicking the “Confirm” button below</span>.
            <br/><br/>
            Please take note that the confirmation of slots shall be on <span className={classes.inlineTextEmphasize}>July 30 - August 2, 2021</span>. &nbsp;
            <span className={classes.inlineTextEmphasizeWarning}>Failure to so confirm within said date shall be deemed waiver of your slot which shall be given to the applicants named in the “wait list”</span>.
            <br/><br/>
            Should you decide to confirm your slot within the period fixed, you shall automatically be eligible to <span className={classes.inlineTextEmphasize}>download your Notice of Admission to the Program (NoAP)</span> from the system. 
            Kindly present your NoAP upon enrollment which is scheduled on <span className={classes.inlineTextEmphasize}>August 9-10, 2021</span> for incoming First Year. 
            Please constantly monitor the USeP Official Website (www.usep.edu.ph) and Official Facebook Page (https://www.facebook.com/USePofficial) for further announcements and other information relative to enrollment.
            <br/><br/>
            Should you have further queries, please don’t hesitate to email us through this address: admissionsunit@usep.ed.ph. 
            <br/><br/>
	        Thank you and welcome to the University.
        </Typography>
    );

    const resultMsgQualifiedWaitlisted = (
        <Typography variant="body" className={classes.resultMsg}>
            We are pleased to inform you that after slots have been vacated, you are now &nbsp;  
            <span className={classes.inlineTextEmphasize}>ONE of the qualifiers</span> in your preferred course as indicated above 
            for the Academic Year 2021-2022 pursuant to the results of evaluation of your application. 
            Hence, we request you to <span className={classes.inlineTextEmphasize}>confirm your slot by clicking the “Confirm” button below</span>.
            <br/><br/>
            Please take note that the confirmation of slots shall be on <span className={classes.inlineTextEmphasize}>August 3 - August 5, 2021</span>. &nbsp;
            <span className={classes.inlineTextEmphasizeWarning}>Failure to so confirm within said date shall be deemed waiver of your slot.</span>.
            <br/><br/>
            Should you decide to confirm your slot within the period fixed, you shall automatically be eligible to <span className={classes.inlineTextEmphasize}>download your Notice of Admission to the Program (NoAP)</span> from the system. 
            Kindly present your NoAP upon enrollment which is scheduled on <span className={classes.inlineTextEmphasize}>August 9-10, 2021</span> for incoming First Year. 
            Please constantly monitor the USeP Official Website (www.usep.edu.ph) and Official Facebook Page (https://www.facebook.com/USePofficial) for further announcements and other information relative to enrollment.
            <br/><br/>
            Should you have further queries, please don’t hesitate to email us through this address: admissionsunit@usep.ed.ph. 
            <br/><br/>
	        Thank you and welcome to the University.
        </Typography>
    );


    const resultMsgEnlisted = (
        <Typography variant="body" className={classes.resultMsg}>
            You have already confirmed your slot for admission to your preferred course as indicated above.
            <br/><br/>
            Please download your Notice of Admission to the Program (NoAP) from the system.
            Kindly present your NoAP upon enrollment which is scheduled on <span className={classes.inlineTextEmphasize}>August 9-10, 2021</span> for incoming First Year.
            Please constantly monitor the USeP Official Website (www.usep.edu.ph) and Official Facebook Page (https://www.facebook.com/USePofficial) for further announcements and other information relative to enrollment.
            <br/><br/>
            Should you have further queries, please don’t hesitate to email us through this address: admissionsunit@usep.ed.ph.      
            <br/><br/>
	        Thank you and welcome to the University.
        </Typography>
    );

    const resultMsgNotQualified = (
        <Typography variant="body" className={classes.resultMsg}>
            We thank you for your trust in this University by sending your application for admission.
            However, after thorough evaluation of your application, we regret to inform you that you 
            &nbsp;<span className={classes.inlineTextEmphasize}>DID NOT QUALIFY</span> for enrolment for the Academic Year 2021-2022. 
            <br/><br/>
            You may, however file your application for the next Academic Year (2022-2023) as soon as announcements 
            to that effect has been made by the University online. Kindly monitor and make a constant visit to our 
            official website www.usep.edu.ph for updates.   
            <br/><br/>
	        THANK YOU.
        </Typography>
    );

    const resultMsgTransferee = (
        <Typography variant="body" className={classes.resultMsg}>
            <span className={classes.inlineTextEmphasize}>TO ALL TRANSFEREE-APPLICANTS</span>
            <br/><br/>
            We thank you for your trust in this University by sending your application for admission for AY 2021-2022. 
            Please be advised that the results of your applications for admission shall be sent to you by email anytime between 
            July 29, 2021 and August 6, 2021 by the Admissions Unit (AU), Office of the University Registrar (OUR). 
            Hence, kindly monitor your emails during the said period. 
            <br/><br/>
	        Thank you.
        </Typography>
    );
    const resultMsgWaitlistedNoVacant = (
        <Typography variant="body" className={classes.resultMsg}>
            The program you applied for as specified above and for which you opted to wait for a vacated slot is already closed.
            However, you may still opt to avail of a <span className={classes.inlineTextEmphasize} style={{color:"green"}}>GUARANTEED</span> slot
            in any of the programs listed below.
            <br/><br/>
            Kindly select ONE by hitting the corresponding SELECT button of your course of choice. 
            Once you hit the button, you shall be deemed to have given your confirmation that you have taken the slot. 
            Please take note that the confirmation of slots shall be on <span className={classes.inlineTextEmphasize}>August 3 - August 5, 2021</span> only.
            <br/><br/>
            Should you decide to confirm your slot within the period fixed, you shall automatically be eligible to <span className={classes.inlineTextEmphasize}>download your Notice of Admission to the Program (NoAP)</span> from the system. 
            Kindly present your NoAP upon enrollment which is scheduled on <span className={classes.inlineTextEmphasize}>August 9-10, 2021</span> for incoming First Year. 
            Please constantly monitor the USeP Official Website (www.usep.edu.ph) and Official Facebook Page (https://www.facebook.com/USePofficial) for further announcements and other information relative to enrollment.
            <br/><br/>
            Should you have further queries, please don’t hesitate to email us through this address: admissionsunit@usep.ed.ph. 
            <br/><br/>
	        Thank you.
        </Typography>
    );
    const resultMsgWaitlisted = (
        <Typography variant="body" className={classes.resultMsg}>
            We are pleased to inform you that <span className={classes.inlineTextEmphasize}>you are included in the pool of waitlisted applicants</span> 
            &nbsp; in your preferred course as indicated above for the Academic Year 2021-2022 pursuant to the results of evaluation of your application. 
            Should anyone from the identified qualified applicants for the said course waives his/her slot, 
            the same shall be given to the waitlisted applicants according to rank. 
            <br/><br/>
            You can wait until after August 2, 2021 to determine whether or not you become eligible to take a vacated slot.
            You may log in to this system again on August 3, 2021 to verify if you are accepted into the program applied for.
            <br/>
            <span className={classes.inlineTextEmphasizeWarning}>IMPORTANT: In the event that no slots are vacated in the program applied for after August 2, 2021, 
            you may lose your chance of admission to the University.</span>
            <br/><br/>
            <span className={classes.inlineTextEmphasize}>ALTERNATIVELY</span>, should you not wish to wait for an availability of slot in your preferred course as specified above, 
            you may also opt to avail of a <span className={classes.inlineTextEmphasize} style={{color:"green"}}>GUARANTEED</span> slot in any of the programs listed below. 
            Kindly select ONE by hitting the corresponding SELECT button of your course of choice. 
            Once you hit the button, you shall be deemed to have given your confirmation that you have taken the slot. 
            Please take note that the confirmation of slots shall be on <span className={classes.inlineTextEmphasize}>August 3 - August 5, 2021.</span> 
            &nbsp;<span className={classes.inlineTextEmphasizeWarning}>Failure to so confirm within said date shall be deemed waiver of your slot which shall be given to the applicants named in the “wait list”</span>.
            <br/><br/>
            Should you decide to confirm your slot within the period fixed, you shall automatically be eligible to <span className={classes.inlineTextEmphasize}>download your Notice of Admission to the Program (NoAP)</span> from the system. 
            Kindly present your NoAP upon enrollment which is scheduled on <span className={classes.inlineTextEmphasize}>August 9-10, 2021</span> for incoming First Year. 
            Please constantly monitor the USeP Official Website (www.usep.edu.ph) and Official Facebook Page (https://www.facebook.com/USePofficial) for further announcements and other information relative to enrollment.
            <br/><br/>
            Should you have further queries, please don’t hesitate to email us through this address: admissionsunit@usep.ed.ph. 
            <br/><br/>
	        Thank you.
        </Typography>
    );
    const resultMsgNotFound = (
        <Typography variant="body" className={classes.resultMsg}>
            Application not found.
            <br/>
            Please check the application number and/or passcode you entered.
        </Typography>
    );
    const resultMsgWaivedSlot = (
        <Typography variant="body" className={classes.resultMsg}>
            You have failed to confirm your slot for admission within the allowable time period, hence your slot
            has been waived. 
        </Typography>
    );

    return(
        <Container maxWidth="md" style={{marginBottom:"15px"}}>
            {/* Result Details*/}
            {props.applicantNo ? 
                <Grid item container xs={12} sm={12}>
                    <div className={classes.formHeader}><Typography variant="h6" style={{width:"100%",textAlign:"center"}}>Application Result</Typography></div>
                    <Grid item container xs={12} sm={5}>
                        <Paper style={{minHeight:"150px",minWidth:"50%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                            {/* <IconButton  style={{fontSize:"1.2rem"}}>
                                <CameraAltIcon />
                            </IconButton> */}
                            <img src={props.applicantProfilePhoto ? props.applicantProfilePhoto.fileSrc : ''} width="50%"  display="block" />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={7} style={{padding:'0 10px'}}>
                        {props.applicantIsEnlisted ? 
                            <Typography className={classes.resultRemarks} style={{fontSize:'1.0rem',fontWeight:"bold"}}>ENLISTED</Typography> 
                        : null }
                        {props.applicantStatus === 'Qualified' && !props.applicantIsEnlisted ? <Typography className={classes.resultRemarks} style={{fontSize:'1.0rem',fontWeight:"bold"}}>QUALIFIED</Typography> : null }
                        {props.applicantStatus === 'Waitlisted' ? <Typography className={classes.resultRemarksWaitlist} style={{fontSize:'1.0rem',fontWeight:"bold"}}>WAITLISTED</Typography> : null }
                        {props.applicantStatus === 'WaivedSlot' ? <Typography className={classes.resultRemarksWaivedSlot} style={{fontSize:'1.0rem',fontWeight:"bold"}}>WAIVED SLOT</Typography> : null }
                        <Typography style={{fontSize:'1.15rem'}}>{props.applicantNo}</Typography>
                        <Typography style={{fontSize:'1.15rem',fontWeight:'bold'}}>{props.applicantName}</Typography>
                        <Typography style={{fontSize:'0.9rem',margin:'10px auto'}}>{props.applicantProgram} {props.applicantMajor ? ` | ${props.applicantMajor}` : ''}</Typography>
                        <Typography style={{fontSize:'0.9rem',margin:'0px auto'}}>{props.applicantCollege}</Typography>
                        <Typography style={{fontSize:'0.9rem',margin:'0px auto'}}>{props.applicantCampus}</Typography>
                        {/* {props.applicantIsEnlisted ? <Button variant="outlined" color="primary">Download Notice of Admission to the Program</Button> : null } */}
                        {props.applicantIsEnlisted ? <div style={{marginTop:'10px'}}><DownloadNOAP data={NOAP_DATA}/></div> : null }
                    </Grid>
                
                {/* Result Message */}
                <Grid item xs={12} sm={12} style={{marginTop:"20px"}}>
                    {props.applicantStatus === 'Qualified' && !props.applicantIsEnlisted && props.resultsCheckingPeriod === 1 ? resultMsgQualified : null}
                    
                    {/* Checking period = 2 denotes 2nd week/iteration of results checking in which waitlisted applicants
                        may qualify after slots have been vacated in their preferred course due to non-confirmation of original qualifiers
                    */}
                    {props.applicantStatus === 'Qualified' && !props.applicantIsEnlisted && props.resultsCheckingPeriod === 2 ? resultMsgQualifiedWaitlisted : null}
                    
                    {props.applicantStatus === 'Qualified' && props.applicantIsEnlisted ? resultMsgEnlisted : null}

                    {props.applicantStatus === 'NotQualified' && props.applicantTypeID === 1 ? resultMsgNotQualified : null}

                    {props.applicantStatus === 'NotQualified' && props.applicantTypeID === 2 ? resultMsgTransferee : null}

                    {props.applicantStatus === 'Waitlisted'  && props.resultsCheckingPeriod === 1 ?  resultMsgWaitlisted : null}

                    {props.applicantStatus === 'Waitlisted'  && props.resultsCheckingPeriod === 2 ?  resultMsgWaitlistedNoVacant : null}

                    {props.applicantStatus === 'WaivedSlot' ? resultMsgWaivedSlot : null}
                    <br/><br/>
                    {resultSignatory}
                </Grid>

    
            </Grid>
            : null }

            {props.applicantStatus === 'NotFound' ? 
                <Grid item container xs={12} sm={12}>
                    {/* Result Message */}
                    <Grid item xs={12} sm={12} style={{marginTop:"20px"}}>
                        {props.applicantStatus === 'NotFound' ? resultMsgNotFound : null}
                    </Grid>
                </Grid>
            : null}

        </Container>

    )

}

export default applicationResult;