import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import { 
    Button, 
    Card, 
    CardContent, 
    CardHeader, 
    Select, 
    MenuItem, 
    TextField, 
    InputLabel, 
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Paper,
    Slider,
    IconButton,
    ListItem,
    AppBar,
    Toolbar,
    ListItemText,
    Divider,
    Box,
    List,
    Avatar,
    CircularProgress,
    Backdrop,
    FormGroup,
    Switch,
    Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Autocomplete from '@material-ui/lab/Autocomplete';

import landingImage from './../../landing-image.png' ;
import dataPrivacyImage from './../../DataPrivacy.jpg' ;
import usepLogoHeader from './../../usep-logo.png' ; 

import ApplicationTracker from './ApplicationTracker';

import { useHistory } from "react-router-dom";
import { setCourseCategoryToApply } from "../../redux/actions/applicant";

const LandingPage = () => {

    let history = useHistory();

    const applicant = useSelector(state => state.applicant);

    const onSelectCourseCategoryToApply = (courseCategory) => {
        setCourseCategoryToApply(courseCategory.id);
        history.push(`${courseCategory.url}`);
    }

    return (
        <div>
            <Dialog fullScreen open={true}>
                <DialogContent style={{textAlign: "center"}}>
                    <React.Fragment>
                            <Container maxWidth="sm">
                                <img src={usepLogoHeader} style={{width:"20%",display:"block",margin:"0 auto"}} />
                                <img src={landingImage} style={{width:"70%",marginTop:"-22px"}} />
                                <Typography variant="h6" style={{fontSize:"1.5rem"}}>Welcome!</Typography>
                                <Typography variant="h6" style={{fontSize:"1rem"}}>to the</Typography>
                                <Typography variant="h6" style={{fontSize:"1.4rem",color:"#d75d5f"}}>University of Southeastern Philippines</Typography>
                                {/* <Typography variant="h6" style={{marginTop:"-7px"}}>Online Application for Admission</Typography> */}
                            </Container>
                            {/* <Container maxWidth="sm" style={{marginTop:"25px",paddingBottom:"30px"}}>
                            
                                <Typography style={{marginTop:"10px",marginBottom:"10px"}}>
                                    Please select an academic program category to proceed.
                                </Typography>
                                <Button 
                                    style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                    onClick={() => onSelectCourseCategoryToApply({ id:1, url: '/preRegQuestions'}) } >Undergraduate</Button>
                                <Button 
                                    disabled
                                    style={{borderRadius:"1000px",background:"rgb(181 156 157)",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}}
                                    color="gray" 
                                    onClick={() => onSelectCourseCategoryToApply({ id:2, url: '/preRegQuestions'}) } >Graduate</Button>
                                <Button
                                    disabled 
                                    style={{borderRadius:"1000px",background:"rgb(181 156 157)",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                    onClick={() => onSelectCourseCategoryToApply({ id:3, url: '/preRegQuestions'}) } >ETEEAP</Button>
                                </Container> */}
                    </React.Fragment>

                    <React.Fragment>
                        {/* <Typography>OR</Typography> */}
                        <Typography>Track Your Application</Typography>
                        <ApplicationTracker/>

                    </React.Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default LandingPage;