import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import dataPrivacyImage from '../../DataPrivacy.jpg' ;
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import { 
    Button, 
    Card, 
    CardContent, 
    CardHeader, 
    Select, 
    MenuItem, 
    TextField, 
    InputLabel, 
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Paper,
    Slider,
    IconButton,
    ListItem,
    AppBar,
    Toolbar,
    ListItemText,
    Divider,
    Box,
    List,
    Avatar,
    CircularProgress,
    Backdrop,
    FormGroup,
    Switch,
    Checkbox
} from '@material-ui/core';

import { setDataPrivacyConsent } from "../../redux/actions/applicant";


const DataPrivacyAgreement = () => {

    let history = useHistory();

    const onSetDataPrivacyConsent = (data) => {
        setDataPrivacyConsent(data);
        if(data === 1) 
            history.push('/regForm')
        else
            history.push('/')    
    }

    return (
        <div>
            {/* Data Privacy Dialog */}
            <Dialog fullScreen open >
                <DialogContent style={{textAlign: "center"}}>
                   
                    <Container maxWidth="lg">
                        <img src={dataPrivacyImage} style={{width:"70%"}} />
                        <Typography variant="h6" style={{fontSize:"1.4rem",color:"#d75d5f"}}>PRIVACY NOTICE</Typography>
                        <Typography variant="h6" style={{marginTop:"-7px"}}></Typography>
                    </Container>
                    <Container maxWidth="sm" style={{marginTop:"25px",paddingBottom:"30px"}}>
                        
                            <Typography align="justify" style={{marginBottom:"10px"}}>
                                Pursuant to the University of Southeastern Philippines (USeP) data privacy policy and the Data Privacy Act of 2012, we will collect and process the following personal information from you when you manually or electronically fill in this form:
                            </Typography>
                            <List component="nav">
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Full name" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Date and Place of Birth" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Gender" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Marital Status" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Citizenship" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Present and Permanent Address" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Email Address" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Contact Number" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Family Background" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Scholastic and Educational Background" />
                                </ListItem>
                            </List>
                            <Typography align="justify" style={{marginTop:"10px",fontWeight:"bold"}}>
                                USE: The collected personal information will be utilized solely for student admission, profiling and CHEd reportorial requirement purposes. 
                            </Typography>
                            <Typography align="justify" style={{marginTop:"10px",fontWeight:"bold"}}>
                                PROTECTION MEASURE: These personal information shall be held in utmost confidentiality and shall not be transferred or divulged to other persons or entity without your express consent. Only authorized personnel can have access to this personal information. The Admissions Unit - Office of the University Registrar (AU-OUR) will only retain the collected personal data as long as necessary for the fulfilment of the purpose. 
                            </Typography>
                            <Typography align="justify" style={{marginTop:"10px"}}>
                                Click 'I Agree' if you agree to the collection and processing of your personal information.
                                Otherwise, choose 'I Disagree' to return to the previous page.
                            </Typography>
                            <Button 
                                style={{margin:"0 20px", borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onSetDataPrivacyConsent(1)} >I Agree</Button>
                            <Button 
                                style={{margin:"0 20px",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => onSetDataPrivacyConsent(0)} >I Disagree</Button>
                    </Container>
                    
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DataPrivacyAgreement;