import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a0036',
    },
    secondary: {
      main: green[500],
      contrastText: '#fff'
    },
  },
  typography: {
    fontFamily: `"Quicksand", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    
  }
});

export default theme;