import axios from 'axios';
// import { useDispatch } from 'react-redux';

import {dispatch} from "./index";
import {CONFIG} from "../../AppConfig";

const URL = CONFIG.URL_API;


export const setIsFilipinoAction = (data) => {
    // return {
    //     type: "IS_FILIPINO_SET",
    //     payload: {
    //         isFilipino: data
    //     }
    // }
    // const dispatch = useDispatch();
    let payload = {};
    payload.isFilipino = data;

    dispatch("IS_FILIPINO_SET", payload);
}

export const setCourseCategoryToApply = (data) => {
    let payload = {};
    payload.courseCategoryID = data;

    dispatch("COURSE_CATEGORY_APPPLIED_SET", payload);
}


export const setApplicantEntryType = (data) => {
    let payload = {};
    payload.applicantEntryTypeID = data;

    dispatch("APPLICANT_ENTRY_TYPE_SET", payload);
}

export const setDataPrivacyConsent = (data) => {
    let payload = {};
    payload.hasAgreedToDataPrivacyStatement = data;

    dispatch("DATA_PRIVACY_CONSENT_SET", payload);
}

export const setIsK12Graduate = (data) => {
    let payload = {};
    payload.isK12Graduate = data;

    dispatch("K12_GRADUATE_SET", payload);
}

export const setIsVocationalCourseGraduate = (data) => {
    let payload = {};
    payload.isVocationalCourseGraduate = data;

    dispatch("VOCATIONAL_COURSE_GRADUATE_SET", payload);
}

export const getApplicationStatus = (data) => {
    let payload = {};

    axios.post(
        `${URL}/admission/getApplicationStatus`,
        data
    ).then( (response) => {
        console.log(`responseObj: ${JSON.stringify(response)}`);
        payload = { ...response.data };
        console.log(`payloadObj: ${JSON.stringify(payload)}`);
        
        dispatch("APPLICATION_STATUS_SET", response.data);
        
    })

    // console.log(`payloadObj: ${JSON.stringify(payload)}`);
}

export const setForeignApplicantDetails = (data) => {
    let payload = {};
}

export const setForeignLastName = (data) => {
    let payload = {};
    payload.foreignLastName = data;

    dispatch("FOREIGN_LAST_NAME_SET", payload);
}

export const setForeignFirstName = (data) => {
    let payload = {};
    payload.foreignFirstName = data;

    dispatch("FOREIGN_FIRST_NAME_SET", payload);
}
export const setForeignMiddleName = (data) => {
    let payload = {};
    payload.foreignMiddleName = data;

    dispatch("FOREIGN_MIDDLE_NAME_SET", payload);
}
export const setForeignSuffix = (data) => {
    let payload = {};
    payload.foreignSuffix = data;

    dispatch("FOREIGN_SUFFIX_SET", payload);
}
export const setForeignOtherName = (data) => {
    let payload = {};
    payload.foreignOtherName= data;

    dispatch("FOREIGN_OTHER_NAME_SET", payload);
}
export const setForeignPassportNumber = (data) => {
    let payload = {};
    payload.foreignPassportNumber = data;

    dispatch("FOREIGN_PASSPORT_NUMBER_SET", payload);
}
export const setForeignPassportPlaceIssued = (data) => {
    let payload = {};
    payload.foreignPassportPlaceIssued = data;

    dispatch("FOREIGN_PASSPORT_PLACEISSUED_SET", payload);
}
export const setForeignPassportDateIssued = (data) => {
    let payload = {};
    payload.foreignPassportDateIssued = data;

    dispatch("FOREIGN_PASSPORT_DATEISSUED_SET", payload);
}
export const setForeignPassportExpiryDate = (data) => {
    let payload = {};
    payload.foreignPassportExpiryDate = data;

    dispatch("FOREIGN_PASSPORT_EXPIRYDATE_SET", payload);
}

export const getApplicantPassportPhoto = (props) => {
    let payload = {};

    axios.get(
        `${URL}/admission/getUploadedFile/${props.appNo}/${props.fileID}`
    ).then( (response) => {
        // console.log(`APP_PHOTO: ${JSON.stringify(response.data)}`);

        payload = {...response.data};
        dispatch("PASSPORT_PHOTO_SET", payload);
    })
        
}